import React, { useState } from 'react';
import { Card, Typography, Input, Button, Space, Alert } from 'antd';
import { fetchUserKycData } from '../../../redux/thunks/kyc.thunk';
import { APICallStatus } from '../../../constants';
import AuditorUserDetailsModal from '../../modals/AuditorUserDetailsModal';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { showErrorToast } from '../../../service/toast';

function ApprovedUsers() {
  const [searchQueryText, setSearchQueryText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [modalUserData, setModalUserData] = useState();

  const handleModalClose = () => {
    setModalUserData(null);
    setSearchQueryText('');
  };

  const searchUserData = async () => {
    setIsLoading(true);
    const response = await fetchUserKycData({
      query: searchQueryText,
    });
    setIsLoading(false);
    if (response?.status === APICallStatus.SUCCESS) {
      setModalUserData(response?.userData);
    }
    else{
      showErrorToast(response?.message || "Error in fetching user data")
    }
  };

  return (
    // <div style={{ padding: '24px' }}>
    <>
      <Card
        className="d-flex flex-column"
        title={
          <Typography.Title level={4} style={{ margin: 0 }}>
            KYC Details
          </Typography.Title>
        }
        style={{
          width: 400,
          boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
          borderRadius: '8px',
        }}
      >
        <div
          style={{
            position: 'relative',
            marginBottom: '24px',
          }}
        >
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Alert
            message="Enter user Booking Id/UUID/Mobile Number to view KYC details of User"
            type="info"
            showIcon
          />
          <Input
            size="large"
            prefix={<UserOutlined />}
            allowClear={true}
            onPressEnter={searchUserData}
            placeholder="Search by Booking Id, UUID or Phone Number"
            value={searchQueryText}
            onChange={(e) => setSearchQueryText(e.target.value)}
          />
           </Space>
        </div>
        <Button
          type="primary"
          icon={<SearchOutlined />}
          style={{
            backgroundColor: !searchQueryText ? "white" : '#6425FE',
          }}
          loading={isLoading}
          onClick={searchUserData}
          disabled={!searchQueryText}
          block
          size="large"
        >
          Get KYC Details
        </Button>
        
      </Card>
      <AuditorUserDetailsModal
        visible={modalUserData}
        userDetails={modalUserData}
        onClose={handleModalClose}
        isActionableModal={false}
      />
    </>
  );
}

export default ApprovedUsers;