import React, { useEffect, useState } from 'react';
import { fetchAccountClosureData, closeWallet, fetchCloseWalletRefundDetails, fetchCloseWalletRefundPaymentGatewayDetails, initiateRefund } from '../../service/ApiService';
import { fetchClosureWalletBalance } from '../../redux/thunks/account.thunk';
import axios from 'axios';
import { message } from 'antd';

import { Card, Space, Alert, Table, Spin, Drawer, Collapse, Input, Button, Modal } from 'antd';
import { APICallStatus } from '../../constants';
import { useNavigate } from 'react-router-dom';
const { Panel } = Collapse;

const AccountClosure = () => {
    const navigate = useNavigate();
    const [accountClosureData, setAccountClosureData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalResults, setTotalResults] = useState(0);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [walletBalance, setWalletBalance] = useState(null);
    const [walletDetails, setWalletDetails] = useState(null);
    const [walletError, setWalletError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [walletClosureRefundDetailsErrorMessage, setWalletClosureRefundDetailsErrorMessage] = useState('No refund details available.');
    const [walletClosureDetails, setWalletClosureDetails] = useState(null);
    const [closeWalletDisabled, setCloseWalletDisabled] = useState(false);
    const [loadingRefundDetails, setLoadingRefundDetails] = useState(false);
    const [paymentGatewayDetails, setPaymentGatewayDetails] = useState(null);
    const [loadingPaymentGatewayDetails, setLoadingPaymentGatewayDetails] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [refundRecord, setRefundRecord] = useState(null);

    useEffect(() => {
        console.log('Fetching account closure data...', currentPage);
        const getData = async () => {
            if (!currentPage) return;
            setLoading(true);
            try {
                const data = await fetchAccountClosureData(searchQuery, currentPage, navigate);
                console.log('API Response:', data);
                setAccountClosureData(data.data);
                setTotalResults(data.totalResults);
            } catch (error) {
                console.error('Failed to fetch account closure data:', error);
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, [currentPage, searchQuery]);

    const handleTableChange = (pagination) => {
        console.log('Pagination changed:', pagination);
        if (pagination) {
            setCurrentPage(pagination);
        }
    };

    const handleRowClick = async (record) => {
        setSelectedRecord(record);
        console.log("Record", record, record?.uuid);
        setIsDrawerVisible(true);
        setWalletBalance(null); // Reset wallet balance
        setWalletError(null); // Reset wallet error
        setWalletDetails(null); // Reset wallet details
        setWalletClosureDetails(null); // Reset wallet closure details
        setCloseWalletDisabled(false); // Enable the close wallet button
        try {
            const balanceData = await fetchClosureWalletBalance(record.uuid, navigate);
            console.log("Balance Data", balanceData);
            if (balanceData?.status === APICallStatus.SUCCESS) {
                setWalletBalance(balanceData.data.balance);
                setWalletDetails(balanceData.data);
                if(balanceData?.data?.walletStatus!=="ACTIVE"){
                    setCloseWalletDisabled(true);
                }
            } else {
                setWalletError(balanceData.message);
            }
        } catch (error) {
            console.error('Failed to fetch wallet balance:', error);
            setWalletError('Failed to fetch wallet balance. Please try again later.');
        }
    };

    const resetStates = () => {
        setWalletClosureDetails(null);
        setPaymentGatewayDetails(null);
        setLoadingRefundDetails(false);
        setLoadingPaymentGatewayDetails(false);
        setWalletClosureRefundDetailsErrorMessage('No refund details available.');
        setCloseWalletDisabled(false);
    };

    const handleDrawerClose = () => {
        resetStates();
        setIsDrawerVisible(false);
        setSelectedRecord(null);
    };

    const handleSearch = async (value) => {
        setSearchQuery(value);
        setCurrentPage(1);
        setLoading(true);
        try {
            const data = await fetchAccountClosureData(value, 1, navigate);
            setAccountClosureData(data.data);
            setTotalResults(data.totalResults);
        } catch (error) {
            console.error('Failed to fetch account closure data:', error);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        { title: 'Ticket ID', dataIndex: 'ticketId', key: 'ticketId' },
        { 
            title: 'Name', 
            dataIndex: 'userInfoDetails', 
            key: 'userInfoDetails.fullName', 
            render: (userInfoDetails) => userInfoDetails?.fullName
        },
        { title: 'Status', dataIndex: 'status', key: 'status' },
        { 
            title: 'Created Timestamp', 
            dataIndex: 'createdTimestamp', 
            key: 'createdTimestamp', 
            render: (timestamp) => {
                return new Date(timestamp).toLocaleString();
            }
        }
    ];

    const handleCloseWallet = async () => {
        Modal.confirm({
            title: 'Are you sure you want to close the wallet?',
            content: 'This action cannot be undone.',
            onOk: async () => {
                const payload = {
                    metaData: {},
                    data: {
                        uuid: selectedRecord.uuid,
                        ticketId: selectedRecord.id,
                        action: "APPROVE",
                        comment: "User Left"
                    }
                };
                try {
                    const response = await closeWallet(payload, navigate);
                    if (response.status === 'SUCCESS') {
                        console.log('Wallet closed successfully:', response.data);
                        const updatedWalletDetails = { ...walletDetails, walletStatus: 'CLOSED' }; // Create a new object with updated status
                        setWalletDetails(updatedWalletDetails); // Update state with new object
                        setWalletClosureDetails(response.data.data.walletClosureRefundDetailsDAO); // Store the refund details
                        setCloseWalletDisabled(true); // Disable the close wallet button
                    } else {
                        console.error('Error closing wallet:', response.error);
                    }
                } catch (error) {
                    console.error('Failed to close wallet:', error);
                }
            },
        });
    };

    const handleFetchRefundDetails = async () => {
        if (!walletClosureDetails) { // Check if details are not already fetched
            setLoadingRefundDetails(true); // Set loading state
            const refundDetailsResponse = await fetchCloseWalletRefundDetails({
                metaData: {},
                data: {
                    uuid: selectedRecord.uuid,
                }
            }, navigate);
            setLoadingRefundDetails(false); // Reset loading state
            if (refundDetailsResponse.status === 'SUCCESS') {
                setWalletClosureDetails(refundDetailsResponse.data);
                if (refundDetailsResponse.data && refundDetailsResponse.data.refundId) {
                    setLoadingPaymentGatewayDetails(true); // Set loading state for payment gateway details
                    const paymentGatewayResponse = await fetchCloseWalletRefundPaymentGatewayDetails({
                        metaData: {},
                        data: {
                            uuid: refundDetailsResponse.data.refundId,
                        }
                    }, navigate);
                    setLoadingPaymentGatewayDetails(false); // Reset loading state
                    if (paymentGatewayResponse.status === 'SUCCESS') {
                        setPaymentGatewayDetails(paymentGatewayResponse.data);
                    } else {
                        console.error('Error fetching payment gateway details:', paymentGatewayResponse.error);
                    }
                }
            } else {
                console.error('Error fetching refund details:', refundDetailsResponse.error);
                setWalletClosureRefundDetailsErrorMessage(refundDetailsResponse.error.shortMessage);
            }
        }
    };

    const handleInitiateRefund = (record) => {
        setRefundRecord(record);
        setIsModalVisible(true);
    };

    const handleModalOk = async () => {
        if (refundRecord) {
            const payload = {
                metaData: {},
                data: {
                    id: refundRecord.id,
                    // Add any other necessary fields here
                }
            };
            const response = await initiateRefund(payload, navigate);
            if (response.status === 'SUCCESS') {
                console.log('Refund initiated successfully:', response.data);
                setRefundRecord(null); // Update the state with the refund record returned in the response
                setPaymentGatewayDetails(prevDetails => {
                    return prevDetails.map(item => 
                        item.id === response.data.id ? response.data : item
                    );
                });
                setIsModalVisible(false);
                // Optionally, refresh the data or provide feedback to the user
            } else {
                message.error(`Error initiating refund: ${response.error.shortMessage}`); // Show error message in popup
                setIsModalVisible(false);
            }
        }
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        setRefundRecord(null);
    };

    return (
        <div>
            {/* <h2>Account Closure</h2> */}
            
            <Card title="Account Closure Data" style={{ marginBottom: '20px' }}>
            <Input.Search
                placeholder="Search..."
                enterButton
                value={searchQuery}
                onSearch={handleSearch}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ marginBottom: '20px', width: '300px', display: 'inline-block' }}
            /> 
            <Button onClick={() => { setSearchQuery(''); handleSearch(''); }} style={{ marginLeft: '10px' }}>Clear</Button>
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <Alert message="View account closure data" type="info" showIcon />
                    {loading ? (
                        <Spin tip="Loading..." style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} />
                    ) : (
                        <Table
                            dataSource={accountClosureData}
                            columns={columns}
                            pagination={{
                                current: currentPage,
                                pageSize: pageSize,
                                total: totalResults,
                                onChange: handleTableChange,
                            }}
                            rowKey="id"
                            onRow={(record) => ({
                                onClick: () => handleRowClick(record), // Handle row click
                            })}
                        />
                    )}
                </Space>
            </Card>
            <Drawer
                title="Record Details"
                placement="right"
                closable={true}
                onClose={handleDrawerClose}
                visible={isDrawerVisible}
                width={1200}
            >
                {selectedRecord && (
                    <div>
                        <Collapse defaultActiveKey={['1']}> 
                            <Panel header="Account Details" key="1">
                                <div style={{ overflowX: 'auto' }}>
                                    <table style={{ width: '100%', marginBottom: '20px' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>ID</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{selectedRecord.id}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>Transaction ID</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{selectedRecord.txnId}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>Ticket ID</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{selectedRecord.ticketId}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>Type</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{selectedRecord.type}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>UUID</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{selectedRecord.uuid}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>Title</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{selectedRecord.title}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>Status</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{selectedRecord.status}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>Created Timestamp</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{new Date(selectedRecord.createdTimestamp).toLocaleString()}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Panel>
                            <Panel header="User Info" key="2">
                                <div style={{ overflowX: 'auto' }}>
                                    <table style={{ width: '100%', marginBottom: '20px' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>Name</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{selectedRecord.userInfoDetails.fullName}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>KYC Status</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{selectedRecord.userInfoDetails.kycStatus}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>Email</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{selectedRecord.userInfoDetails.email}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Panel>
                            <Panel header="Wallet Details" key="4">
                                <div style={{ padding: '8px' }}>
                                    {walletError ? (
                                        <p style={{ color: 'red' }}>{walletError}</p>
                                    ) : walletBalance === null ? (
                                        <Spin tip="Loading..." style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} />
                                    ) : (
                                        <>
                                        <p>Wallet Balance: {walletBalance}</p>
                                        <p>Wallet Status: {walletDetails.walletStatus}</p>
                                        </>
                                        
                                    )}
                                </div>
                            </Panel>
                            {walletDetails && walletDetails.walletStatus === 'CLOSED' && (
                            <Panel header="Wallet Closure Refund Details" key="6" onClick={handleFetchRefundDetails}> 
                                <div style={{ padding: '8px' }}>
                                    {loadingRefundDetails ? (
                                        <Spin tip="Loading..." style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} />
                                    ) : walletClosureDetails ? (
                                        <div>
                                            <p><strong>Refund ID:</strong> {walletClosureDetails.refundId}</p>
                                            <p><strong>Refund Amount:</strong> {walletClosureDetails.refundAmount}</p>
                                            <p><strong>Status:</strong> {walletClosureDetails.status}</p>
                                            <p><strong>Processed Refund Amount:</strong> {walletClosureDetails.processedRefundAmount}</p>
                                            { walletClosureDetails && walletClosureDetails.refundId && loadingPaymentGatewayDetails && (
                                                <Spin tip="Loading Payment Gateway Details..." style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} />
                                            )}
                                            {paymentGatewayDetails && (
                                                <div>
                                                    {loadingPaymentGatewayDetails ? (
                                                        <Spin tip="Loading Payment Gateway Details..." style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} />
                                                    ) : (
                                                        <Table
                                                            dataSource={paymentGatewayDetails}
                                                            columns={[
                                                                { title: 'Transaction Reference ID', dataIndex: 'txnReferenceId', key: 'txnReferenceId' },
                                                                { title: 'Refund ID', dataIndex: 'refundId', key: 'refundId' },
                                                                { title: 'PG Refund ID', dataIndex: 'pgRefundId', key: 'pgRefundId', render: (text) => text ? text : '--' },
                                                                { title: 'Refund Amount', dataIndex: 'refundAmount', key: 'refundAmount' },
                                                                { title: 'Status', dataIndex: 'status', key: 'status' },
                                                                { title: 'Remarks', dataIndex: 'remarks', key: 'remarks', render: (text) => text ? text : '--' },
                                                                { title: 'Action', key: 'action', render: (text, record) => (
                                                                    <Button 
                                                                        type="primary" 
                                                                        style={{ backgroundColor: record.status === 'SUCCESS' ? '#d9d9d9' : '#5cb85c', borderColor: record.status === 'SUCCESS' ? '#d9d9d9' : '#5cb85c', color: record.status === 'SUCCESS' ? 'rgba(0, 0, 0, 0.25)' : 'white' }}
                                                                        disabled={record.status === 'SUCCESS'} 
                                                                        onClick={() => handleInitiateRefund(record)}
                                                                    >
                                                                        Initiate Refund
                                                                    </Button>
                                                                )},
                                                            ]}
                                                            pagination={false}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <p>No refund details available.</p>
                                    )}
                                </div>
                            </Panel>)}
                            <Panel header="Close Wallet" key="5">
                                <div style={{ padding: '8px' }}>
                                    <Button type="primary" onClick={handleCloseWallet} disabled={closeWalletDisabled || walletDetails?.walletStatus === 'CLOSED'}>Close Wallet</Button>
                                </div>
                            </Panel>
                            <Panel header="Messages" key="3">
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    {selectedRecord.messages.sort((a, b) => b.createdDate - a.createdDate).map((msg, index) => (
                                        <div key={index} style={{ display: 'flex', marginBottom: '10px', justifyContent: msg.type === 'USER' ? 'flex-start' : 'flex-end' }}>
                                            <div style={{ 
                                                padding: '10px', 
                                                borderRadius: '5px', 
                                                backgroundColor: msg.type === 'USER' ? '#e1ffc7' : '#d1e7ff', 
                                                maxWidth: '70%', 
                                                wordWrap: 'break-word' 
                                            }}>
                                                <strong>{msg.type}:</strong> {msg.content}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Panel>
                        </Collapse>
                    </div>
                )}
            </Drawer>
            <Modal
                title="Confirm Refund "
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <p>Are you sure you want to initiate a refund for {refundRecord?.refundId} of <strong>Rs. {refundRecord?.refundAmount}</strong>?</p>
            </Modal>
        </div>
    );
};

export default AccountClosure;
