import React, { useState, useEffect } from 'react';
import { styles } from './styles';
import { APICallStatus } from '../../../constants';
import { initiateTransactionRefund } from '../../../redux/thunks/account.thunk';
import { showErrorToast, showSuccessToast } from '../../../service/toast';
import { Card, Input, Button, Space, Typography, Alert, Drawer, Descriptions, Tag, Modal, Select, List, Table, Spin, message, Form } from 'antd';
import { RollbackOutlined, SearchOutlined, UserOutlined, CheckCircleOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { ApiService } from '../../../service/ApiService';
import { useNavigate } from 'react-router-dom';
import { AccessRoles } from '../../../constants';
import { loggedInUserRoleSelector } from '../../../redux/selectors/app';
import { useSelector } from 'react-redux';
import ApprovedUsers from '../ApprovedUsers';

export default function ManageTransactions() {
  const navigate = useNavigate();
  const userRole = useSelector(loggedInUserRoleSelector);
  const isCsaAgent = userRole === AccessRoles.CSA;
  const [searchQueryText, setSearchQueryText] = useState('');
  const [userSearchText, setUserSearchText] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingReferral, setIsLoadingReferral] = useState(false);
  const [isInitiateRefundLoading, setIsInitiateRefundLoading] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editField, setEditField] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [transactionDetailsVisible, setTransactionDetailsVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRotateTpinLoading, setIsRotateTpinLoading] = useState(false);

  useEffect(() => {
    // Removed fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    setIsLoading(true); // Set loading state to true
    try {
      const response = await ApiService.getAllTransactionsByTxnId({
        metaData: {},
        data: { txnId: searchQueryText }
      }, navigate);
      if (response.status === APICallStatus.SUCCESS) {
        setTransactions(response.data);
        setIsModalVisible(true);
      } else {
        Modal.error({
          title: 'Error',
          content: response?.error?.shortMessage || response.message,
          okText: 'Okay',
        });
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
      Modal.error({
        title: 'Error',
        content: 'Failed to fetch transactions',
        okText: 'Okay',
      });
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  const attachCreditPendingReferralReward = async (temporaryReferralCode) => {
    setIsLoadingReferral(true); // Set loading state to true
    console.log("sjbhsjbcjscs", temporaryReferralCode)
    if(!temporaryReferralCode){
      showErrorToast("Please enter referral code to continue.")
      return;
    }
    try {
      const response = await ApiService.attachCreditReferralAmount({
        metaData: {},
        data: { referralCode: temporaryReferralCode, uuid: userDetails?.uuid }
      }, navigate);
      if (response.status === APICallStatus.SUCCESS) {
        message.success('Referral Code Attached successfully!');
        await handleUserSearch()
      } else {
        Modal.error({
          title: 'Error',
          content: response?.error?.shortMessage || response.message,
          okText: 'Okay',
        });
      }
    } catch (error) {
      console.error('Error attaching referral code :', error);
      Modal.error({
        title: 'Error',
        content: error?.message || 'Failed to attach referral code',
        okText: 'Okay',
      });
    } finally {
      setIsLoadingReferral(false); // Reset loading state
      setReferralCode('')
    }
  };


  const creditPendingReferralReward = async () => {
    setIsLoadingReferral(true); // Set loading state to true
    try {
      const response = await ApiService.creditReferralAmount({
        metaData: {},
        data: { uuid: userDetails.uuid }
      }, navigate);
      if (response.status === APICallStatus.SUCCESS) {
        message.success('Referral reward credited successfully!');
        await handleUserSearch()
      } else {
        Modal.error({
          title: 'Error',
          content: response?.error?.shortMessage || response.message,
          okText: 'Okay',
        });
      }
    } catch (error) {
      console.error('Error rewarding referral:', error);
      Modal.error({
        title: 'Error',
        content: error?.message || 'Failed to reward referral',
        okText: 'Okay',
      });
    } finally {
      setIsLoadingReferral(false); // Reset loading state
    }
  };

  const handleTxnRefund = async (id) => {
    setIsInitiateRefundLoading(true); // Set loading state to true
    try {
      const response = await initiateTransactionRefund(id, navigate);
      console.log('xyxyxyx', response);
      if (response?.status === APICallStatus.SUCCESS) {
        showSuccessToast('Transaction refunded successfully!');
        setSelectedTransaction({ ...selectedTransaction, isRefunded: true }); // Update state to disable button
      } else {
        Modal.error({
          title: 'Refund Failed :(',
          content: response.message || 'Failed to refund transaction',
          okText: 'Okay',
        });
      }
    } catch (error) {
      console.error('Error initiating refund:', error);
      Modal.error({
        title: 'Error',
        content: 'An unexpected error occurred while initiating the refund.',
        okText: 'Okay',
      });
    } finally {
      setIsInitiateRefundLoading(false); // Reset loading state
    }
  };

  const handleUserSearch = async () => {
    if (!userSearchText) return;

    setIsUserLoading(true);
    try {
      const response = await ApiService.getUserInfoByUuid(userSearchText, navigate);

      if (response.status === APICallStatus.SUCCESS) {
        setUserDetails(response.data);
        setDrawerVisible(true);
        setReferralCode('')
      } else {
        Modal.error({
          title: 'User Search Failed',
          content: response.message,
          okText: 'Okay',
        });
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      Modal.error({
        title: 'Error',
        content: 'Failed to fetch user details',
        okText: 'Okay',
      });
    } finally {
      setIsUserLoading(false);
    }
  };

  const handleEdit = (field, currentValue) => {
    setEditField(field);
    setEditValue(currentValue);
    setEditModalVisible(true);
  };

  const handleUpdate = async () => {
    if (!editValue) {
      Modal.error({
        title: 'Validation Error',
        content: 'Please enter a value',
        okText: 'Okay',
      });
      return;
    }

    setIsUpdating(true);
    try {
      const response = await ApiService.updateUserDetailsByAdmin(
        userDetails.uuid,
        editField === 'name' ? 'NAME' : 'NATIONALITY',
        editValue,
        navigate
      );

      if (response.status === 'SUCCESS') {
        showSuccessToast(response.message);
        setEditModalVisible(false);

        const updatedUser = await ApiService.getUserInfoByUuid(userDetails.uuid, navigate);
        if (updatedUser.status === APICallStatus.SUCCESS) {
          setUserDetails(updatedUser.data);
        }
      } else {
        Modal.error({
          title: 'Update Failed',
          content: response.message,
          okText: 'Okay',
        });
      }
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: 'Failed to update user details',
        okText: 'Okay',
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleRotateTpin = () => {
    Modal.confirm({
      title: 'Confirm Rotate TPIN',
      content: 'Are you sure you want to rotate the TPIN?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        setIsRotateTpinLoading(true);
        console.log('Attempting to rotate TPIN for user:', userDetails.uuid);
        try {
          const response = await ApiService.rotateTpin(userDetails.uuid, navigate);
          console.log('Rotate TPIN response:', response);
          if (response.status === 'SUCCESS') {
            Modal.success({
              title: 'Success',
              content: 'TPIN rotated successfully!',
            });
          } else {
            Modal.error({
              title: 'Error',
              content: response.message || 'Failed to rotate TPIN.',
            });
          }
        } catch (error) {
          console.error('Error during TPIN rotation:', error);
          Modal.error({
            title: 'Error',
            content: 'An error occurred while rotating TPIN.',
          });
        } finally {
          setIsRotateTpinLoading(false);
        }
      },
    });
  };

  const handleTransactionClick = (transaction) => {
    setSelectedTransaction(transaction);
    setTransactionDetailsVisible(true);
  };

  return (
    <div className='d-flex flex-row' style={{ ...styles.container, gap: '24px' }}>
      {/* Transaction Refund Card */}
      <Card
        title={
          <Typography.Title level={4} style={{ margin: 0 }}>
            {isCsaAgent ? 'Transaction details' : 'Transaction Refunds'}
          </Typography.Title>
        }
        style={{
          width: 400,
          boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
          borderRadius: '8px'
        }}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Alert
            message="Please verify the transaction ID/ Reference Id before initiating refund"
            type="info"
            showIcon
          />

          <Input
            placeholder="Enter transaction ID / Reference Id"
            prefix={<SearchOutlined />}
            value={searchQueryText}
            onChange={(e) => setSearchQueryText(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                fetchTransactions(); // Call fetchTransactions on Enter key press
              }
            }}
            size="large"
            allowClear
          />

          <Button
            type="primary"
            icon={<RollbackOutlined />}
            onClick={fetchTransactions}
            loading={isLoading}
            disabled={!searchQueryText}
            size="large"
            block
            style={{ backgroundColor: !searchQueryText ? "white" : '#6425FE' }}
          >
            Fetch Transactions
          </Button>
        </Space>
      </Card>

      {/* User Management Card */}
      <Card
        title={
          <Typography.Title level={4} style={{ margin: 0 }}>
            User Management
          </Typography.Title>
        }
        style={{
          width: 400,
          boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
          borderRadius: '8px'
        }}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Alert
            message="Enter user UUID/Mobile Number to view details"
            type="info"
            showIcon
          />

          <Input
            placeholder="Enter user UUID/Mobile Number"
            prefix={<UserOutlined />}
            value={userSearchText}
            onChange={(e) => setUserSearchText(e.target.value)}
            size="large"
            allowClear
          />

          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={handleUserSearch}
            loading={isUserLoading}
            disabled={!userSearchText}
            size="large"
            block
            style={{ backgroundColor: !userSearchText ? "white" : '#6425FE' }}
          >
            Search User
          </Button>
        </Space>
      </Card>

      {!isCsaAgent && <ApprovedUsers/>}

      {/* User Details Drawer */}
      <Drawer
        title={
          <Space>
            <UserOutlined />
            <Typography.Title level={4} style={{ margin: 0 }}>
              User Details
            </Typography.Title>
          </Space>
        }
        placement="right"
        onClose={() => {
          setDrawerVisible(false);
          setUserDetails(null);
          setUserSearchText('');
        }}
        open={drawerVisible}
        width={800}
        styles={{
          header: {
            padding: '16px 24px',
            borderBottom: '1px solid #f0f0f0',
            color: 'white'
          },
          title: {
            color: 'white'
          },
          body: {
            padding: '24px'
          }
        }}
      >
        {userDetails ? (
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Alert
              message={`User Status: ${userDetails.status}`}
              type={userDetails.status === 'ACTIVE' ? 'success' : 'error'}
              showIcon
              style={{ marginBottom: '24px' }}
            />

            <Descriptions
              column={1}
              bordered
              labelStyle={{
                fontWeight: 600,
                backgroundColor: '#fafafa'
              }}
            >
              <Descriptions.Item label="UUID">
                <Typography.Text copyable>{userDetails.uuid}</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Name">
                <Space>
                  {userDetails.fullName}
                  {!isCsaAgent && (
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => handleEdit('name', userDetails.fullName)}
                    />
                  )}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                <Typography.Text copyable>{userDetails.email}</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Email Verified">
                {true ? <>Verified <CheckCircleOutlined style={{ color: 'green' }} /></> : <>Not Verified <CloseCircleOutlined style={{ color: 'red' }} /></>}
              </Descriptions.Item>
              <Descriptions.Item label="Phone">
                <Typography.Text copyable>{userDetails.phoneNumber}</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Country">
                {userDetails.countryCode}
              </Descriptions.Item>
              <Descriptions.Item label="Nationality Type">
                <Space>
                  {userDetails.nationalityType}
                  {!isCsaAgent && (
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => handleEdit('nationality', userDetails.nationalityType)}
                    />
                  )}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="KYC Status">
                {userDetails.kycStatus}
              </Descriptions.Item>
              <Descriptions.Item label="TPIN Exists">
                {userDetails.tpinExists ? 'Yes' : 'No'}
                {!isCsaAgent && (
                  <Button
                    type="primary"
                    onClick={handleRotateTpin}
                    loading={isRotateTpinLoading}
                    style={{ marginLeft: 10 }}
                  >
                    Rotate TPIN
                  </Button>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Sim Binding Status">
                <Space>
                  {userDetails.simBindingStatus}
                  {userDetails.simBindingStatus === 'PENDING' && (
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title: 'Confirm SIM Binding Update',
                          content: 'Are you sure you want to update the SIM binding status to SUCCESS?',
                          okText: 'Yes',
                          cancelText: 'No',
                          onOk: async () => {
                            try {
                              const response = await ApiService.updateUserDetailsByAdmin(
                                userDetails.uuid,
                                'SIM_BINDING',
                                'true'
                              );

                              if (response.status === 'SUCCESS') {
                                showSuccessToast(response.message);

                                // Refresh user details
                                const updatedUser = await ApiService.getUserInfoByUuid(userDetails.uuid);
                                if (updatedUser.status === APICallStatus.SUCCESS) {
                                  setUserDetails(updatedUser.data);
                                }
                              } else {
                                // Show error modal
                                if (response.errorCode === 'M1345E') {
                                  Modal.error({
                                    title: 'Session Expired',
                                    content: 'Please restart the app to apply updates. Close it from the background and reopen.',
                                    okText: 'Okay',
                                  });
                                } else {
                                  Modal.error({
                                    title: 'SIM Binding Update Failed',
                                    content: response.shortMessage || "Something went wrong.",
                                    okText: 'Okay',
                                  });
                                }
                              }
                            } catch (error) {
                              console.error('Error updating SIM binding:', error);
                              Modal.error({
                                title: 'Error',
                                content: 'Failed to update SIM binding',
                                okText: 'Okay',
                              });
                            }
                          }
                        });
                      }}
                    />
                  )}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Actions">
                {!isCsaAgent && (
                  <Button
                    type="primary"
                    danger
                    style={{ marginTop: 8 }}
                    onClick={() => {
                      Modal.confirm({
                        title: 'Confirm User Disable',
                        content: 'Are you sure you want to disable this user?',
                        okText: 'Yes',
                        cancelText: 'No',
                        onOk: async () => {
                          try {
                            const response = await ApiService.updateUserDetailsByAdmin(
                              userDetails.uuid,
                              'DISABLE_USER',
                              'true'
                            );

                            if (response.status === 'SUCCESS') {
                              showSuccessToast(response.message);

                              // Refresh user details
                              const updatedUser = await ApiService.getUserInfoByUuid(userDetails.uuid);
                              if (updatedUser.status === APICallStatus.SUCCESS) {
                                setUserDetails(updatedUser.data);
                              }
                            } else {
                              // Show error modal
                              if (response.errorCode === 'M1345E') {
                                Modal.error({
                                  title: 'Session Expired',
                                  content: 'Please restart the app to apply updates. Close it from the background and reopen.',
                                  okText: 'Okay',
                                });
                              } else {
                                Modal.error({
                                  title: 'User Disable Failed',
                                  content: response.shortMessage,
                                  okText: 'Okay',
                                });
                              }
                            }
                          } catch (error) {
                            console.error('Error disabling user:', error);
                            Modal.error({
                              title: 'Error',
                              content: 'Failed to disable user',
                              okText: 'Okay',
                            });
                          }
                        }
                      });
                    }}
                    disabled={userDetails.status === 'DISABLED'}
                  >
                    Disable User
                  </Button>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="First Transaction Timestamp">
                <Typography.Text copyable>{new Date(Number(userDetails.firstTransactionTimestamp)).toLocaleString()}</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Cooling Period Over Timestamp">
                <Typography.Text copyable>{new Date(Number(userDetails.coolingPeriodFinishTimestamp)).toLocaleString()}</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Referral Status">
                {userDetails.isReferredUser ? (
                  <Tag color="blue">Referred User</Tag>
                ) : (
                  <>
                  <Tag color="default">Not Referred</Tag>
                  <Button type='primary' 
                  onClick={() => {
                    let temporaryReferralCode = ''
                    Modal.confirm({
                      title: 'Enter Referral Code to Map to this User.',
                      content: (
                        <Form layout="vertical">
                          <Form.Item label="Referral Code">
                            <Input
                              onChange={(e) => {
                                console.log("cacaxaxaxa", e.target.value)
                                temporaryReferralCode = e.target.value
                              }}
                              placeholder="Enter referral code"
                            />
                          </Form.Item>
                        </Form>
                      ),
                      okText: 'Confirm',
                      cancelText: 'Cancel',
                      onOk: async () => {
                        await attachCreditPendingReferralReward(temporaryReferralCode);
                      },
                    });
                  }}
                  >
                    Attach Referral Code
                  </Button>
                  </>
                )}
              </Descriptions.Item>
            </Descriptions>

            {(userDetails.isReferredUser) && (userDetails.referralDetails) && <Typography.Title level={4}>Referral Details</Typography.Title>}
            <Descriptions column={1}
              bordered
              labelStyle={{
                fontWeight: 600,
                backgroundColor: '#fafafa'
              }}>

              {(userDetails.isReferredUser) && (userDetails.referralDetails) && (
                <>
                  <Descriptions.Item label="Status">
                    <Tag color={userDetails?.referralDetails?.status === 'PENDING' ? 'orange' : 'green'}>
                      {userDetails?.referralDetails?.status}
                    </Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Referred By">
                    <span><Typography.Text copyable>{userDetails?.referredByUsername}</Typography.Text></span>
                    <div>
                    <Typography.Text copyable={{text: userDetails?.referralDetails?.referredByUuid}}>Copy UUID</Typography.Text>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item label="Referral Amount">
                    <Space direction="vertical">
                      <span>To Referee: INR {userDetails?.referralDetails?.amountToCreditToReferee}</span>
                      <span>To Referrer: INR {userDetails?.referralDetails?.amountToCreditToReferrer}</span>
                    </Space>
                  </Descriptions.Item>
                  <Descriptions.Item label="Current Message">
                    {userDetails?.referralDetails?.currentMessage}
                  </Descriptions.Item>
                  <Descriptions.Item label="Credit Referral Amount">
                  <Button
                    type="primary"
                    success
                    style={{ marginTop: 8 }}
                    onClick={() => {
                      Modal.confirm({
                        title: 'Confirm Credit Referral Amount?',
                        content: 'Are you sure you want to credit referral amount to this user and referer?',
                        okText: 'Yes',
                        cancelText: 'No',
                        onOk: async () => {
                          await creditPendingReferralReward()
                        }
                      })
                    }}
                    disabled={isCsaAgent || userDetails?.referralDetails?.status == 'CLOSED'}
                  >
                    Credit Referral
                  </Button>
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>

            <Card size="small" title="Additional Details" bordered={false}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <div>
                  <Typography.Text type="secondary">Created At:</Typography.Text>
                  <div>{new Date(userDetails.createdTimestamp).toLocaleString()}</div>
                </div>
                <div>
                  <Typography.Text type="secondary">Last Updated:</Typography.Text>
                  <div>{new Date(userDetails.updatedTimestamp).toLocaleString()}</div>
                </div>
              </Space>
            </Card>

            <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
              <Button
                type="primary"
                danger
                onClick={() => {
                  setDrawerVisible(false);
                  setUserDetails(null);
                  setUserSearchText('');
                }}
              >
                Close
              </Button>
            </Space>
          </Space>
        ) : (
          <div style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Space direction="vertical" align="center">
              <img
                src="/loading.gif"
                alt="Loading..."
                style={{ width: 50, height: 50 }}
              />
              <Typography.Text type="secondary">
                Fetching user details...
              </Typography.Text>
            </Space>
          </div>
        )}
      </Drawer>

      {/* Transaction Details Drawer */}
      <Drawer
        title="Transaction Details"
        placement="right"
        closable={true}
        onClose={() => setTransactionDetailsVisible(false)}
        visible={transactionDetailsVisible}
        width={800}
      >
        {selectedTransaction && (
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Descriptions title="Transaction Information" bordered column={1} layout="horizontal">
              <Descriptions.Item label="Transaction ID" span={3}>{selectedTransaction.txnId}</Descriptions.Item>
              <Descriptions.Item label="Amount" span={3}>{selectedTransaction.amount}</Descriptions.Item>
              <Descriptions.Item label="UTR Number" span={3}>{selectedTransaction.utrNumber}</Descriptions.Item>
              <Descriptions.Item label="Reference ID" span={3}>{selectedTransaction.referenceId}</Descriptions.Item>
              <Descriptions.Item label="Callback Transaction ID" span={3}>{selectedTransaction.callbackTxnId}</Descriptions.Item>
              <Descriptions.Item label="Payee Name" span={3}>{selectedTransaction.name}</Descriptions.Item>
              <Descriptions.Item label="Type" span={3}>{selectedTransaction.type}</Descriptions.Item>
              <Descriptions.Item label="VPA" span={3}>{selectedTransaction.vpa}</Descriptions.Item>
              <Descriptions.Item label="Timestamp" span={3}>{new Date(parseInt(selectedTransaction.timestamp)).toLocaleString()}</Descriptions.Item>
              <Descriptions.Item label="Payment Status" span={3}>
                <Tag color={
                  selectedTransaction.status === 'PAYMENT_SUCCESS' ? 'green' :
                    selectedTransaction.status === 'PAYMENT_FAILURE' ? 'red' :
                      selectedTransaction.status === 'PAYMENT_PROCESSING' ? 'orange' : 'default'
                }>
                  {selectedTransaction.status || 'N/A'}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Message" span={3}>{selectedTransaction.message ? selectedTransaction.message : 'N/A'}</Descriptions.Item>
            </Descriptions>

            <Descriptions title="Transfer Information" bordered column={1} layout="horizontal">
              <Descriptions.Item label="Transfer Code" span={3}>{selectedTransaction.respCode ? selectedTransaction.respCode : 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Transfer Message" span={3}>{selectedTransaction.respCodeMessage ? selectedTransaction.respCodeMessage : 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Transfer Status" span={3}>
                <Tag color={
                  selectedTransaction.respCodeStatus === 'SUCCESS' ? 'green' :
                    selectedTransaction.respCodeStatus === 'FAILURE' ? 'red' :
                      selectedTransaction.respCodeStatus === 'PROCESSING' ? 'orange' : 'default'
                }>
                  {selectedTransaction.respCodeStatus || 'N/A'}
                </Tag>
              </Descriptions.Item>
            </Descriptions>

            <Descriptions title="Refund Information" bordered column={1} layout="horizontal">
              <Descriptions.Item label="Is Refunded" span={3}>{selectedTransaction.isRefunded ? 'Yes' : 'No'}</Descriptions.Item>
              <Descriptions.Item label="Refund Status" span={3}>
                <Tag color={selectedTransaction.refundStatus === 'SUCCESS' ? 'green' : selectedTransaction.refundStatus === 'FAILURE' ? 'red' : 'default'}>
                  {selectedTransaction.refundStatus || 'N/A'}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Refund Timestamp" span={3}>{selectedTransaction.refundTimestamp ? new Date(selectedTransaction.refundTimestamp).toLocaleString() : 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Refund Reference ID" span={3}>{selectedTransaction.refundReferenceId || 'NA'}</Descriptions.Item>
            </Descriptions>

            {isCsaAgent ? null : <Button
              type="primary"
              style={{ backgroundColor: selectedTransaction.isRefunded ? '#d9d9d9' : '#6425FE', borderColor: selectedTransaction.isRefunded ? '#d9d9d9' : '#6425FE', color: selectedTransaction.isRefunded ? '#595959' : '#ffffff' }}
              loading={isInitiateRefundLoading}
              disabled={selectedTransaction.isRefunded}
              onClick={async () => {
                await handleTxnRefund(selectedTransaction.id);
                // Optionally, you can add success/error handling here
              }}
            >
              Initiate Refund
            </Button>}

          </Space>
        )}
      </Drawer>

      {/* Edit Modal */}
      <Modal
        title={`Edit ${editField === 'name' ? 'Name' : 'Nationality Type'}`}
        open={editModalVisible}
        onCancel={() => {
          setEditModalVisible(false);
          setEditField(null);
          setEditValue('');
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setEditModalVisible(false);
              setEditField(null);
              setEditValue('');
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isUpdating}
            onClick={handleUpdate}
          >
            Update
          </Button>
        ]}
      >
        {editField === 'name' ? (
          <Input
            placeholder="Enter new name"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
          />
        ) : (
          <Select
            style={{ width: '100%' }}
            value={editValue}
            onChange={(value) => setEditValue(value)}
            options={[
              { value: 'NRI', label: 'NRI' },
              { value: 'FOREIGNER', label: 'FOREIGNER' }
            ]}
          />
        )}
      </Modal>

      <Modal
        title={<span>Transactions matching <span style={{ color: '#4A90E2' }}>#{searchQueryText}</span></span>}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setSearchQueryText(''); // Clear the input field
        }}
        footer={null}
        width="100%"
        style={{ top: 0 }}
        bodyStyle={{ padding: '0', height: '100vh' }}
      >
        {isLoading ? (
          <Spin size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} />
        ) : (
          <div style={{ height: 'calc(100vh - 64px)', overflowY: 'auto', padding: '20px' }}>
            <Table
              dataSource={transactions}
              rowKey="txnId"
              pagination={false}
              columns={[
                {
                  title: 'Payer Name',
                  dataIndex: 'payerName',
                  key: 'payerName',
                },
                {
                  title: 'Transaction ID',
                  dataIndex: 'txnId',
                  key: 'txnId',
                },
                {
                  title: 'Amount',
                  dataIndex: 'amount',
                  key: 'amount',
                },
                {
                  title: 'Date',
                  dataIndex: 'timestamp',
                  key: 'timestamp',
                  render: (text) => {
                    const date = new Date(parseInt(text));
                    return date.toString() !== 'Invalid Date' ? date.toLocaleString() : 'N/A'; // Handle invalid date
                  },
                },
                {
                  title: 'Status',
                  dataIndex: 'status',
                  key: 'status',
                },
                {
                  title: 'Action',
                  key: 'action',
                  render: (text, record) => (
                    <Button
                      type="link"
                      onClick={() => handleTransactionClick(record)}
                    >
                      View Details
                    </Button>
                  ),
                },
              ]}
              onRow={(record) => ({
                onClick: () => handleTransactionClick(record), // Clickable row
              })}
            />
          </div>
        )}
      </Modal>
    </div>
  );
}
