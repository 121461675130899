import axios from "axios";
import { Config } from "../constants/config";
import { accessTokenSelector } from "../redux/selectors/auth";
import store from "../redux/store";
import { message } from "antd";

// const getHeaders = () => {
//   const { logOut, user } = useUserAuth();

//   return {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${user.accessToken}`,
//     },
//   };
// };


const postApi = (url, params) => {
  // let accessToken = '';
  // if(window.location.href?.split('?')?.length > 1) {
  //   const queryParams = window.location.href?.split('?')[1].split('&');
  //   queryParams.forEach(param => {
  //     if(param.indexOf('accessToken') > -1) {
  //       accessToken = param?.split('=')[1];
  //     }
  //   });
  // }
  const accessToken = accessTokenSelector(store.getState());
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      source: 'web',
      deviceid: Math.random(),
    },
  };
  const body = {
    metaData: {
    },
    data: {
      ...params
    },
  };
  console.log(body, 'api request body')
  return axios.post(
    `${process.env.REACT_APP_BACKEND_DOMAIN}${url}`,
    body,
    headers,
  );
}

const postApiFileUpload = (url, body) => {
  // let accessToken = '';
  // if(window.location.href?.split('?')?.length > 1) {
  //   const queryParams = window.location.href?.split('?')[1].split('&');
  //   queryParams.forEach(param => {
  //     if(param.indexOf('accessToken') > -1) {
  //       accessToken = param?.split('=')[1];
  //     }
  //   });
  // }
  console.log(body);
  
  const accessToken = accessTokenSelector(store.getState());
  const headers = {
    headers: {
      'Content-Type': 'multipart/form-data', // Important!
      Authorization: `Bearer ${accessToken}`,
      source: 'web',
      deviceid: Math.random(),
    },
  };
  console.log(body, 'api request body')
  return axios.post(
    `${process.env.REACT_APP_BACKEND_DOMAIN}${url}`,
    body,
    headers,
  );
}

const postTokenApi = (url, params) => {
  let accessToken = '';
  if(window.location.href?.split('?')?.length > 1) {
    const queryParams = window.location.href?.split('?')[1].split('&');
    queryParams.forEach(param => {
      if(param.indexOf('accessToken') > -1) {
        accessToken = param?.split('=')[1];
      }
    });
    if(accessToken?.length === 0) {
      return;
    }
  } else {
    return;
  }
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      source: 'web',
      deviceid: Math.random(),
    },
  };

  const body = {
    metaData: {
      // userId: 22,
    },
    data: {
      ...params
    },
  };
  return axios.post(
    `${process.env.REACT_APP_BACKEND_DOMAIN}${url}`,
    body,
    headers,
  );
}

const sendPushNotification = async (params) => {
  return await postApi(Config.apis.sendPushNotification, params);
}

const getUserInfoByUuid = async (uuid, navigate) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1/auth/getUserInfoByUuid`, {
      metaData: {},
      data: {
        uuid
      }
    }, {
      headers: {
        'Source': 'web',
        'apiversion': '1',
        'Content-Type': 'application/json',
        deviceid: Math.random(),
        'Authorization': `Bearer ${accessTokenSelector(store.getState())}`
      }
    });

    if (response.data?.status === 'SUCCESS') {
      return {
        status: 'SUCCESS',
        data: response.data.data
      };
    }
    
    // Handle error response
    if (response.data?.status === 'ERROR') {
      return {
        status: 'ERROR',
        message: response.data.error?.shortMessage || 'Failed to fetch user details',
        errorCode: response.data.error?.errorCode
      };
    }

    return {
      status: 'ERROR',
      message: 'Failed to fetch user details'
    };
  } catch (error) {
    console.error('Error in getUserInfoByUuid:', error);
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    
    // Handle axios error response
    if (error.response?.data?.status === 'ERROR') {
      return {
        status: 'ERROR',
        message: error.response.data.error?.shortMessage || 'Failed to fetch user details',
        errorCode: error.response.data.error?.errorCode
      };
    }
    
    return {
      status: 'ERROR',
      message: error.response?.data?.message || 'Failed to fetch user details'
    };
  }
};

const updateUserDetailsByAdmin = async (uuid, key, value, navigate) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1/auth/updateUserDetailsByAdmin`, {
      metaData: {},
      data: {
        uuid,
        key,
        value
      }
    }, {
      headers: {
        'Source': 'web',
        'apiversion': '1',
        'Content-Type': 'application/json',
        deviceid: Math.random(),
        'Authorization': `Bearer ${accessTokenSelector(store.getState())}`
      }
    });

    if (response.data?.status === 'SUCCESS') {
      return {
        status: 'SUCCESS',
        message: 'User details updated successfully'
      };
    }
    
    // Handle error response
    if (response.data?.status === 'ERROR') {
      return {
        status: 'ERROR',
        message: response.data.error?.shortMessage || 'Failed to update user details',
        errorCode: response.data.error?.errorCode
      };
    }
    
    return {
      status: 'ERROR',
      message: 'Failed to update user details'
    };
  } catch (error) {
    console.error('Error in updateUserDetailsByAdmin:', error);
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    
    // Handle axios error response
    if (error.response?.data?.status === 'ERROR') {
      return {
        status: 'ERROR',
        message: error.response.data.error?.shortMessage || 'Failed to update user details',
        errorCode: error.response.data.error?.errorCode
      };
    }
    
    return {
      status: 'ERROR',
      message: error.response?.data?.message || 'Failed to update user details'
    };
  }
};

export const getAllTransactionsByTxnId = async (data, navigate) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1/txn/getAllTransactionsByTxnId`,
      data
    , {
      headers: {
        'Source': 'web',
        'apiversion': '1',
        'Content-Type': 'application/json',
        deviceid: Math.random(),
        'Authorization': `Bearer ${accessTokenSelector(store.getState())}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching transactions:', error);
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    throw new Error(error.response?.data?.message || 'Failed to fetch transactions');
  }
};

export const creditReferralAmount = async (data, navigate) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1/wallet/creditReferralRewardAdmin`,
      data
    , {
      headers: {
        'Source': 'web',
        'apiversion': '1',
        'Content-Type': 'application/json',
        deviceid: Math.random(),
        'Authorization': `Bearer ${accessTokenSelector(store.getState())}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error crediting referral:', error);
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    throw new Error(error.response?.data?.error?.shortMessage || 'Failed to credit referral');
  }
};

export const attachCreditReferralAmount = async (data, navigate) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1/wallet/attachCreditReferralRewardAdmin`,
      data
    , {
      headers: {
        'Source': 'web',
        'apiversion': '1',
        'Content-Type': 'application/json',
        deviceid: Math.random(),
        'Authorization': `Bearer ${accessTokenSelector(store.getState())}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error attaching referral code:', error);
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    throw new Error(error.response?.data?.error?.shortMessage || 'Failed to attach referral code');
  }
};

export const rotateTpin = async (uuid, navigate) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1/auth/rotateTpin`,
      {metaData: {},
      data: {
        uuid
      }}
    , {
      headers: {
        'Source': 'web',
        'apiversion': '1',
        'Content-Type': 'application/json',
        deviceid: Math.random(),
        'Authorization': `Bearer ${accessTokenSelector(store.getState())}`
      }
    });
    if (response.data?.status === 'SUCCESS') {
      return {
        status: 'SUCCESS',
        message: response.data?.message
      };
    }
    // Handle axios error response
    if (response?.data?.status === 'ERROR') {
      return {
        status: 'ERROR',
        message: response.data.error?.shortMessage || 'Failed to change user\'s TPIN',
        errorCode: response.data.error?.errorCode
      };
    }
  } catch (error) {
    console.error('Error changing Tpin:', error);
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    return {
      status: 'ERROR',
      message: error.response.data.error?.shortMessage || 'Failed to change user\'s TPIN',
    };
  }
};

export const fetchAccountClosureData = async (query, pageNumber, navigate) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1/tickets/getAllAccountClosureTickets`, {
      metaData: {},
      data: {
        pageNumber: `${pageNumber}`,
        query
      }
    }, {
      headers: {
        'Source': 'web',
        'apiversion': '1',
        'Content-Type': 'application/json',
        deviceid: Math.random(),
        'Authorization': `Bearer ${accessTokenSelector(store.getState())}`
      }
    });
    if (response.data?.status === 'SUCCESS') {
      return {
        status: 'SUCCESS',
        data: response.data.data.list,
        totalResults: Number(response.data.data.totalResults)*20 // Assuming the API returns total results
      };
    }
    // Handle error response
    if (response.data?.status === 'ERROR') {
      return {
        status: 'ERROR',
        message: response.data.error?.shortMessage || 'Failed to fetch account closure data',
        errorCode: response.data.error?.errorCode
      };
    }
    return {
      status: 'ERROR',
      message: 'Failed to fetch account closure data'
    };
  } catch (error) {
    console.error('Error fetching account closure data:', error);
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    return {
      status: 'ERROR',
      message: error.response?.data?.message || 'Failed to fetch account closure data'
    };
  }
};

export const closeWallet = async (payload, navigate) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1/wallet/closeWallet`, payload,
      {
        headers: {
          'Source': 'web',
          'apiversion': '1',
          'Content-Type': 'application/json',
          deviceid: Math.random(),
          'Authorization': `Bearer ${accessTokenSelector(store.getState())}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error closing wallet:', error);
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    throw error;
  }
};

export const fetchCloseWalletRefundDetails = async (payload, navigate) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1/wallet/closeWalletRefundDetails`, payload,
      {
        headers: {
          'Source': 'web',
          'apiversion': '1',
          'Content-Type': 'application/json',
          deviceid: Math.random(),
          'Authorization': `Bearer ${accessTokenSelector(store.getState())}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching wallet closure refund details:', error);
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    return error.response.data;
  }
};

export const fetchCloseWalletRefundPaymentGatewayDetails = async (payload, navigate) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1/wallet/closeWalletRefundPaymentGatewayDetails`, payload,
      {
        headers: {
          'Source': 'web',
          'apiversion': '1',
          'Content-Type': 'application/json',
          deviceid: Math.random(),
          'Authorization': `Bearer ${accessTokenSelector(store.getState())}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching wallet closure refund payment gateway details:', error);
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    throw error;
  }
};

export const initiateRefund = async (payload, navigate) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1/wallet/issuePgRefund`, payload, {
      headers: {
        'Source': 'web',
        'apiversion': '1',
        'Content-Type': 'application/json',
        deviceid: Math.random(),
        'Authorization': `Bearer ${accessTokenSelector(store.getState())}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error initiating refund:', error);
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    return error.response.data;
  }
};

const generatePaymentLinkAdmin = async (uuid, amount, navigate) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1/wallet/generatePaymentLinkAdmin`, {
      metaData: {},
      data: {
        uuid,
        amount
      }
    }, {
      headers: {
        'Source': 'web',
        'apiversion': '1',
        'Content-Type': 'application/json',
        deviceid: Math.random(),
        'Authorization': `Bearer ${accessTokenSelector(store.getState())}`
      }
    });

    if (response.data?.status === 'SUCCESS') {
      return {
        status: 'SUCCESS',
        data: response.data.data
      };
    }
    
    // Handle error response
    if (response.data?.status === 'ERROR') {
      return {
        status: 'ERROR',
        message: response.data.error?.shortMessage || 'Failed to generate payment link',
        errorCode: response.data.error?.errorCode
      };
    }

    return {
      status: 'ERROR',
      message: 'Failed to generate payment link'
    };
  } catch (error) {
    console.error('Error in generate payment link:', error);
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    
    // Handle axios error response
    if (error.response?.data?.status === 'ERROR') {
      return {
        status: 'ERROR',
        message: error.response.data.error?.shortMessage || 'Failed to generate payment link',
        errorCode: error.response.data.error?.errorCode
      };
    }
    
    return {
      status: 'ERROR',
      message: error.response?.data?.message || 'Failed to generate payment link'
    };
  }
};


const getAllManualPaymentLinksAdmin = async (pageNumber, navigate) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1/wallet/getAllManualPaymentLinksAdmin`, {
      metaData: {},
      data: {
        pageNumber
      }
    }, {
      headers: {
        'Source': 'web',
        'apiversion': '1',
        'Content-Type': 'application/json',
        deviceid: Math.random(),
        'Authorization': `Bearer ${accessTokenSelector(store.getState())}`
      }
    });

    if (response.data?.status === 'SUCCESS') {
      return {
        status: 'SUCCESS',
        data: response.data.data
      };
    }
    
    // Handle error response
    if (response.data?.status === 'ERROR') {
      return {
        status: 'ERROR',
        message: response.data.error?.shortMessage || 'Failed to get payment links',
        errorCode: response.data.error?.errorCode
      };
    }

    return {
      status: 'ERROR',
      message: 'Failed to get payment links'
    };
  } catch (error) {
    console.error('Error in get payment links:', error);
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    
    // Handle axios error response
    if (error.response?.data?.status === 'ERROR') {
      return {
        status: 'ERROR',
        message: error.response.data.error?.shortMessage || 'Failed to get payment links',
        errorCode: error.response.data.error?.errorCode
      };
    }
    
    return {
      status: 'ERROR',
      message: error.response?.data?.message || 'Failed to get payment links'
    };
  }
};

export const ApiService = {
  sendPushNotification,
  postApi,
  postTokenApi,
  postApiFileUpload,
  getUserInfoByUuid,
  updateUserDetailsByAdmin,
  getAllTransactionsByTxnId,
  rotateTpin,
  fetchAccountClosureData,
  closeWallet,
  fetchCloseWalletRefundDetails,
  fetchCloseWalletRefundPaymentGatewayDetails,
  initiateRefund,
  creditReferralAmount,
  attachCreditReferralAmount,
  generatePaymentLinkAdmin,
  getAllManualPaymentLinksAdmin
}