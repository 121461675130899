import { useNavigate } from "react-router-dom";
import { styles } from './styles';
import { theme } from "../../../constants/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCheckSquare, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { APICallStatus } from "../../../constants";
import { fetchAuditorKycUserList } from "../../../redux/thunks/kyc.thunk";
import AuditorUserDetailsModal from "../../modals/AuditorUserDetailsModal";
import layout from "../../../constants/layout";
import Lottie from "react-lottie";
import LogoAnimation from '../../../assets/lotties/logoLoaderAnimation.json';

function LoaderPage({
  success = null,
  message = '',
}) {
  const defaultOptions = {
    loop: success === null,
    autoplay: true, 
    animationData: LogoAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [showStatus, setShowStatus] = useState(false);

  useEffect(() => {
    if (success !== null) {
      const timer = setTimeout(() => {
        setShowStatus(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  return (
    <div style={styles.container}>
      {!showStatus ? (
        <Lottie options={defaultOptions}
          height={layout.window.toNormW(200)}
          width={layout.window.toNormW(200)}
        />
      ) : (
        <div style={styles.statusContainer}>
          <FontAwesomeIcon 
            icon={success ? faCheckSquare : faTimesCircle} 
            style={success ? styles.successIcon : styles.failureIcon}
          />
          <p style={{...styles.statusText, color:success? theme.COLOR.SuccessGreen:theme.COLOR.Red}}>
            {(success ? 'Payment Successful' : 'Payment Failed')}
          </p>
          <p style={styles.statusSubText}>
            {message || (success ? 'Payment Successful' : 'Payment Failed')}
          </p>
        </div>
      )}
    </div>
  );
}

export default LoaderPage;
