import layout from "../../../constants/layout";
import { theme } from "../../../constants/theme";

export const styles = {
  container: {
    paddingLeft: layout.window.toNormW(22),
    paddingRight: layout.window.toNormW(22),
    paddingTop: layout.window.toNormH(22),
    paddingBottom: layout.window.toNormH(22),
    // position: 'relative',
    // flex: 1,
  },
  actionCard: {
    borderRadius: layout.window.toNormW(10),
    borderWidth: layout.window.toNormW(1), 
    borderStyle: 'solid',
    borderColor: theme.COLOR.LightGrey,
    paddingLeft: layout.window.toNormW(22),
    paddingRight: layout.window.toNormW(22),
    paddingTop: layout.window.toNormH(22),
    paddingBottom: layout.window.toNormH(22),
    minHeight: layout.window.toNormH(200),
    minWidth: layout.window.toNormH(450)
    // width: layout.window.toNormW(300),
  },
  cardTitle: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimarySemiBold,
  },
  cardSubmitCta: {
    marginTop: layout.window.toNormH(40)
  }
};