import { message as antdMessage } from 'antd';

export const showErrorToast = (msg) => {
  antdMessage.error(msg);
}

export const showSuccessToast = (msg) => {
  antdMessage.success(msg);
}

export const showInfoToast = (msg) => {
  antdMessage.info(msg);
}