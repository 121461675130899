import React, { useState, useEffect } from "react";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Select, Tag, Input, Space, Tooltip, DatePicker, message } from 'antd';
import { EditOutlined, WhatsAppOutlined, EyeOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faMessage } from "@fortawesome/free-solid-svg-icons";
import { accessTokenSelector } from "../../../redux/selectors/auth";
import store from "../../../redux/store";
import "./styles.css"
import moment from 'moment';
import ReactImageMagnify from "react-image-magnify";
import { Modal } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { theme } from "../../../constants/theme";
import { showErrorToast, showSuccessToast } from "../../../service/toast";
const isLocal = false

const StatusBar = ({ count, total, color, label }) => {
  const percentage = (count / total) * 100;
  return (
    <div
      style={{
        flex: count / total,
        backgroundColor: color,
        position: "relative",
        transition: "all 0.3s ease",
        minWidth: count > 0 ? "32px" : "0",
        borderRadius: "4px",
        height: "36px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.05)"
      }}
      title={`${label}: ${count} (${percentage.toFixed(1)}%)`}
    >
      {percentage > 8 && (
        <div style={{
          position: "absolute",
          width: "100%",
          textAlign: "center",
          color: "white",
          fontSize: "14px",
          fontWeight: "600",
          lineHeight: "36px",
          textShadow: "0 1px 2px rgba(0,0,0,0.2)",
          letterSpacing: "0.2px"}}>
          {count}
        </div>
      )}
    </div>
  );
};

const StatusLegend = ({ color, label, count }) => {
  return (
    <div style={{ 
      display: 'flex', 
      alignItems: 'center',
      gap: '6px'
    }}>
      <div style={{ 
        width: '10px', 
        height: '10px', 
        backgroundColor: color,
        borderRadius: '2px'
      }} />
      <span style={{ 
        color: '#666',
        fontSize: '13px'
      }}>
        {label}: <strong>{count}</strong>
      </span>
    </div>
  );
};

const PreKycDashboard = () => {
  const navigate = useNavigate();
  const accessToken = accessTokenSelector(store.getState());
  const [showAgentTracking, setShowAgentTracking] = useState(false);
  const [data, setData] = useState([
    // {
    //   name: "John Doe",
    //   phone: "+1 123 456 7890",
    //   category: "General",
    //   nationality: "American",
    // },
  ]);

  const [filteredData, setFilteredData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [filterChanged, setFilterChanged] = useState(false);
  const [filter, setFilter] = useState("All"); // Default filter value
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [editingPassport, setEditingPassport] = useState(null);


  const [countsPending, setPendingCount] = useState(0);
  const [countsUpdateDoc, setUpdateDocCount] = useState(0);
  const [countsYtaDoc, setYtaCount] = useState(0);
  const [countsHold, setHoldCount] = useState(0);

  useEffect(() => {
    async function fetchCounts() {
      try {
        const response = await axios.post(isLocal ? 'http://localhost:8080/v1/kyc/getPreKycDashboardLeadsCounts' : "https://prod-lb-n-1003.monyyapp.com/api/v1/kyc/getPreKycDashboardLeadsCounts", {
          metaData: {},
          data: {}
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'source': 'web',
            deviceid: Math.random()
          }
        });

        const { data } = response.data;
        
        // Map response data to state
        const counts = data.reduce((acc, item) => {
          acc[item.preKycStatus] = item.count;
          return acc;
        }, {});

        setPendingCount(counts['PENDING']);
        setUpdateDocCount(counts['DOCUMENTS_UPDATE_REQUIRED']);
        setHoldCount(counts['HOLD']);
        setYtaCount(counts['YTA'])
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    }

    fetchCounts();
  }, []);



  const rowsPerPage = 20;

  const handleOpenModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage("");
  };

  useEffect(() => {
    console.log("i am here", currentPage)
    const fetchData = async () => {
      await fetchPaginatedData();
      // setFilterChanged(false)
    };
    fetchData()
  }, [currentPage]);

  // Fetch data on component mount
  useEffect(() => {
    console.log("sjbhjkdsjkjskd", filter, currentPage)
    if (currentPage == 1) {
      const fetchData = async () => {
        await fetchPaginatedData();
        // setFilterChanged(false)
      };
      fetchData()
    } else {
      setCurrentPage(1);
    }
  }, [filter]);

  const reset = async () => {
    if (filter == "All") {
      setCurrentPage(1)
    } else {
      setFilter('All')
    }
  }

  const fetchPaginatedData = async () => {
    setSearchQuery('')
    try {
      let data = JSON.stringify({
        "metaData": {},
        "data": {
          "pageNumber": currentPage,
          "filter": filter
        }
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: isLocal ? 'http://localhost:8080/v1/kyc/getPreKycDashboardLeadsPaginated' : 'https://prod-lb-n-1003.monyyapp.com/api/v1/kyc/getPreKycDashboardLeadsPaginated',
        headers: {
          'source': 'web',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          deviceid: Math.random()
        },
        data: data
      };

      try {
        const response = await axios.request(config);
        console.log('xvxvxvxvx', response.data);
        setData(response.data?.data);
        if (!isSearching) setFilteredData(response.data?.data);
      } catch (error) {
        console.error(error);
        if (error?.response?.status === 401) {
          message.error('Session expired. Redirecting to login page.');
          navigate('/login'); // Redirect to the login page
        }
        throw error
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setFilteredData(data); // Reset to default data if search is empty
      setIsSearching(false);
      return;
    }
    setIsSearching(true);
    try {
      let data = JSON.stringify({
        "metaData": {},
        "data": {
          "pageNumber": currentPage,
          "searchQuery": searchQuery.trim(),
          "filter": filter
        }
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: isLocal ? 'http://localhost:8080/v1/kyc/getPreKycDashboardLeadsPaginated' : 'https://prod-lb-n-1003.monyyapp.com/api/v1/kyc/getPreKycDashboardLeadsPaginated',
        headers: {
          'source': 'web',
          'Content-Type': 'application/json',
          deviceid: Math.random(),
          Authorization: `Bearer ${accessToken}`,
        },
        data: data
      };

      try {
        const response = await axios.request(config);
        console.log('xvxvxvxvx', JSON.stringify(response.data));
        setData(response.data?.data);
        setIsSearching(false);
        if (!isSearching) setFilteredData(response.data?.data);
      } catch (error) {
        console.error(error);
        if (error?.response?.status === 401) {
          message.error('Session expired. Redirecting to login page.');
          navigate('/login'); // Redirect to the login page
        }
        throw error
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (row) => {
    console.log(row, "roroor")
    if (!row.category) {
      toast.error("Please choose category!");
      return;
    }
    if (!row.activationCode && row.category == "Category 0") {
      toast.error("Please generate activation code for category 0!");
      return;
    }
    if (!row.action) {
      toast.error("Please choose action!");
      return;
    }
    
    if (row.action==='yta' && !row.ytaDate) {
      toast.error("Please choose date for Yet to Arrive User.!");
      return;
    }
    const data = JSON.stringify({
      metaData: {},
      data: {
        bookingId: row.bookingId,
        category: row.category,
        action: row.action,
        comments: row.comments,
        ytaDate: row.ytaDate,
        passportNumber: row.passportNumber
      },
    });

    console.log(data, "djjjs");


    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: isLocal ? "http://localhost:8080/v1/kyc/submitPreKycDashboardLeadAction" : "https://prod-lb-n-1003.monyyapp.com/api/v1/kyc/submitPreKycDashboardLeadAction",
      headers: {
        source: "web",
        "Content-Type": "application/json",
        deviceid: Math.random(),
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      showSuccessToast("Action submitted successfully!");
      console.log("Response:", response.data);
      fetchPaginatedData()
    } catch (error) {
      showErrorToast(error?.response?.data?.error?.shortMessage||"Failed to submit the action!");
      console.error("Error:", error);
    }
  };

  const generateActivationCode = async (uuid) => {
    const data = JSON.stringify({
      metaData: {},
      data: {
        uuid: uuid,
      },
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: isLocal ? "http://localhost:8080/v1/auth/byPassKycOsvVerificationCode" : "https://prod-lb-n-1003.monyyapp.com/api/v1/auth/byPassKycOsvVerificationCode",
      headers: {
        source: "web",
        "Content-Type": "application/json",
        deviceid: Math.random(),
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      console.log("Response:", JSON.stringify(response.data));

      // Update activation code for the specific row in filteredData
      const updatedData = filteredData.map((item) =>
        item.uuid === uuid
          ? { ...item, activationCode: response.data.data.code }
          : item
      );

      setFilteredData(updatedData); // Trigger re-render with updated data
      toast.success("Activation code generated successfully!");
      return response.data.data.code; // Return the response for further use if needed
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to generate activation code!");
      throw error;
    }
  };

  const handleWhatsAppClick = async (uuid) => {
    try {
      const data = JSON.stringify({
        metaData: {},
        data: {
          uuid: uuid,
        },
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: isLocal ? "http://localhost:8080/v1/auth/getInteraktCustomerChatLink" : "https://prod-lb-n-1003.monyyapp.com/api/v1/auth/getInteraktCustomerChatLink",
        headers: {
          source: "web",
          "Content-Type": "application/json",
          deviceid: Math.random(),
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      };

      const response = await axios.request(config);
      console.log("Response:", JSON.stringify(response.data));
      const link = response.data.data.link;

      if (link) {
        // Open the returned link in a new tab
        window.open(link, '_blank', 'noopener,noreferrer');
      } else {
        // alert('No link returned from the server.');
        toast.error("Failed to fetch WhatsApp link.");
      }
    } catch (error) {
      console.error('Error fetching the WhatsApp link:', error);
      // alert('Failed to fetch WhatsApp link.');
      toast.error("Failed to fetch WhatsApp link.");
    }
  };

  function convertToIST(timestamp) {
    timestamp = Number(timestamp);
    if (!timestamp) {
      return "NA";
    }
    const date = new Date(timestamp);
    const options = {
      timeZone: 'Asia/Kolkata',
      hour12: true,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    };
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    return formatter.format(date);
  }

  const handleDateChange = (date, index) => {
    // Update date directly in the row
    const epochTimestamp = date ? date.unix() * 1000 : null;
    const updatedData = filteredData.map((item, i) =>
      i === index ? { ...item, ytaDate: epochTimestamp } : item
    );
    setFilteredData(updatedData);
  };

  const renderTableRows = () =>
    filteredData.map((row, index) => (
      <tr
        key={index}
        style={{
          backgroundColor: 'white',
          transition: 'all 0.2s ease',
          borderBottom: '1px solid #f0f0f0',
          cursor: 'pointer'
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = '#f8f7fe';
          e.currentTarget.style.boxShadow = '0 2px 8px rgba(0,0,0,0.04)';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = 'white';
          e.currentTarget.style.boxShadow = 'none';
        }}
      >
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>{convertToIST(row.createdTimestamp)}</td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>{row.fullName}</td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>{row.countryCode}</td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>{row.phoneNumber}</td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>{row.dialCode}</td>
        <td className="px-4 py-3 font-medium">{row.bookingId}</td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>{row.ukdLocation ? row.ukdLocation : row.location}</td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>
          {row.activationCode ? (
            <span className="font-medium">{row.activationCode}</span>
          ) : (
            <Button
              type="primary"
              ghost
              size="small"
              onClick={() => generateActivationCode(row.uuid)}
            >
              Generate
            </Button>
          )}
        </td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>
          <Select
            style={{ width: '100%' }}
            size="small"
            value={row.category || "Choose"}
            onChange={(value) => {
              row.category = value;
              setFilteredData([...filteredData]);
            }}
          >
            {["Choose", "Category 0", "Category 1", "Category 2", "Category 3"].map(
              (option, i) => (
                <Select.Option key={i} value={option}>
                  {option}
                </Select.Option>
              )
            )}
          </Select>
        </td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>{row.nationalityType}</td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>
          {editingPassport === index ? (
            <Input
              size="small"
              placeholder="Enter passport number"
              value={row.passportNumber || ""}
              onChange={(e) => {
                const updatedData = filteredData.map((item, i) =>
                  i === index ? { ...item, passportNumber: e.target.value } : item
                );
                setFilteredData(updatedData);
              }}
              onBlur={() => setEditingPassport(null)}
              onPressEnter={() => setEditingPassport(null)}
              autoFocus
              style={{ width: '100px' }}
            />
          ) : (
            <Space>
              <Tooltip title={row.passportNumber || "No passport number"}>
                <span style={{ 
                  maxWidth: '150px', 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis', 
                  whiteSpace: 'nowrap' 
                }}>
                  {row.passportNumber || "Not provided"}
                </span>
              </Tooltip>
              <Button
                type="text"
                size="small"
                icon={<EditOutlined />}
                onClick={() => setEditingPassport(index)}
              />
            </Space>
          )}
        </td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>
          <Tag style={{ fontSize: '13px', padding: '4px 12px' }} color={
            row.preKycStatus === 'AGENT_ASSIGNED' ? 'success' :
            row.preKycStatus === 'DOCUMENTS_UPDATE_REQUIRED' ? 'processing' :
            row.preKycStatus === 'REJECT' ? 'error' :
            row.preKycStatus === 'HOLD' ? 'warning' :
            row.preKycStatus === 'YTA' ? 'cyan' :
            'default'
          }>
            {row.preKycStatus ? (row.preKycStatus === 'DOCUMENTS_UPDATE_REQUIRED' ? 'UPDATE' : row.preKycStatus) : 'PENDING'}
          </Tag>
        </td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <Select
              style={{ width: '100%' }}
              size="small"
              value={row.action || ""}
              onChange={(value) => {
                const updatedData = filteredData.map((item, i) =>
                  i === index ? { ...item, action: value } : item
                );
                setFilteredData(updatedData);
              }}
            >
              <Select.Option value="">Select Action</Select.Option>
              <Select.Option value="assign agent">Assign Agent</Select.Option>
              <Select.Option value="reject">Reject</Select.Option>
              <Select.Option value="update documents">Update Documents</Select.Option>
              <Select.Option value="hold">On Hold</Select.Option>
              <Select.Option value="yta">YTA</Select.Option>
            </Select>
            {row.action === "yta" && (
              <DatePicker
                size="small"
                style={{ width: '100%' }}
                value={row.ytaDate ? moment(Number(row.ytaDate)) : null}
                onChange={(date) => handleDateChange(date, index)}
                format="DD MMM YYYY"
              />
            )}
          </Space>
        </td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>
          {row.preKycStatus === 'YTA' && (
            <Tag color="error">
              {convertToIST(row.ytaDate)?.split(",")[0]}
            </Tag>
          )}
          {row.isEditing ? (
            <Input.TextArea
              size="small"
              placeholder="Add a comment"
              value={row.comments || ""}
              onChange={(e) => {
                const updatedData = filteredData.map((item, i) =>
                  i === index ? { ...item, comments: e.target.value } : item
                );
                setFilteredData(updatedData);
              }}
              onBlur={() => {
                const updatedData = filteredData.map((item, i) =>
                  i === index ? { ...item, isEditing: false } : item
                );
                setFilteredData(updatedData);
              }}
              autoSize
              style={{ width: '100px' }}
            />
          ) : row.comments ? (
            <Space>
              <Tooltip title={row.comments}>
                <span style={{ 
                  maxWidth: '150px', 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis', 
                  whiteSpace: 'nowrap' 
                }}>
                  {row.comments}
                </span>
              </Tooltip>
              <Button
                type="text"
                size="small"
                icon={<EditOutlined />}
                onClick={() => {
                  const updatedData = filteredData.map((item, i) =>
                    i === index ? { ...item, isEditing: true } : item
                  );
                  setFilteredData(updatedData);
                }}
              />
            </Space>
          ) : (
            <Button
              type="link"
              size="small"
              onClick={() => {
                const updatedData = filteredData.map((item, i) =>
                  i === index ? { ...item, isEditing: true } : item
                );
                setFilteredData(updatedData);
              }}
            >
              Add Remarks
            </Button>
          )}
        </td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>
          <Button
            type="primary"
            size="small"
            onClick={() => handleSubmit(row)}
            disabled={!row.category || !row.action || !row.comments}
          >
            Submit
          </Button>
        </td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>
          <Button
            type="text"
            size="small"
            icon={<WhatsAppOutlined style={{ color: '#25d366' }} />}
            onClick={() => handleWhatsAppClick(row.uuid)}
            title="Contact on WhatsApp"
          />
        </td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>
          <Space>
            <Tooltip title={
              <div>
                <div>
                  <p><strong>Address:</strong> {row.addressLine1 || 'NA'} {row.addressLine2 ? `, ${row.addressLine2}` : ''}</p>
                  <p><strong>City:</strong> {row.city || 'NA'}</p>
                  <p><strong>State:</strong> {row.state || 'NA'}</p>
                  <p><strong>Pincode:</strong> {row.pincode || 'NA'}</p>
                  <p><strong>Already in India:</strong> {row.alreadyInIndia === 'false' ? 'No' : 'Yes'}</p>
                </div>
                <Button 
                  type="link" 
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    const address = `${row.addressLine1 || 'NA'}${row.addressLine2 ? `, ${row.addressLine2}` : ''}, ${row.city || 'NA'}, ${row.state || 'NA'}, ${row.pincode || 'NA'}`;
                    navigator.clipboard.writeText(address);
                    message.success('Address copied to clipboard');
                  }}
                  style={{ padding: 3, height: 'auto', marginTop: '8px', backgroundColor:theme.COLOR.ThemePrimary, color:"white" }}
                >
                  Copy Address
                </Button>
              </div>
            } placement="right">
              <Button type="link" size="small">
                View Address
              </Button>
            </Tooltip>
          </Space>
        </td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>
          <Button
            type="link"
            size="small"
            onClick={() => {
              const updatedData = filteredData.map((item, i) =>
                i === index ? { ...item, isEditing: true } : item
              );
              setFilteredData(updatedData);
            }}
          >
            Edit
          </Button>
        </td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>
          <Button
            type="link"
            size="small"
            icon={<EyeOutlined />}
            onClick={() => handleOpenModal(row.passportFrontImage)}
          >
            Front
          </Button>
        </td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>
          <Button
            type="link"
            size="small"
            icon={<EyeOutlined />}
            onClick={() => handleOpenModal(row.passportBackImage)}
          >
            Back
          </Button>
        </td>
        <td style={{
          padding: '16px 20px',
          fontSize: '14px',
          color: '#444',
          verticalAlign: 'middle'
        }}>
          <Button
            type="link"
            size="small"
            icon={<EyeOutlined />}
            onClick={() => handleOpenModal(row.visaImage)}
          >
            Visa
          </Button>
        </td>
      </tr>
    ));

  const toggleAgentTracking = () => {
    setShowAgentTracking(!showAgentTracking);
  };

  if (showAgentTracking) {
    return (
      <div className="pre-kyc-dashboard">
        <div className="dashboard-header" style={{ marginBottom: '20px', display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            type="primary" 
            onClick={toggleAgentTracking}
            style={{ marginTop: '50px',marginRight: '20px', backgroundColor: theme.COLOR.ThemePrimary, position:"absolute" }}
          >
            Back to Dashboard
          </Button>
        </div>
        <iframe
          src="https://mb.havemony.com/public/dashboard/522c45ce-2166-48d4-9006-ec372809ab5c"
          frameBorder="0"
          width="100%"
          height="600px"
          style={{ borderRadius: '8px', boxShadow: '0 2px 8px rgba(0,0,0,0.1)' }}
          allowTransparency
        />
      </div>
    );
  }

  return (
    <div className="p-4">
      <div 
        onClick={toggleAgentTracking}
        style={{ 
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '24px',
          marginBottom: '20px',
          cursor: 'pointer',
          boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
          border: '1px solid #f0f0f0',
          transition: 'all 0.2s ease'
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.transform = 'translateY(-2px)';
          e.currentTarget.style.boxShadow = '0 4px 12px rgba(0,0,0,0.12)';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform = 'none';
          e.currentTarget.style.boxShadow = '0 2px 8px rgba(0,0,0,0.08)';
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <div style={{
            width: '48px',
            height: '48px',
            borderRadius: '12px',
            backgroundColor: `${theme.COLOR.ThemePrimary}15`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <FontAwesomeIcon 
              icon={faChartBar} 
              style={{ 
                fontSize: '24px',
                color: theme.COLOR.ThemePrimary
              }}
            />
          </div>
          <div>
            <h3 style={{ 
              margin: 0,
              fontSize: '18px',
              fontWeight: '600',
              color: '#333',
              marginBottom: '4px'
            }}>
              KYC Agent Tracking
            </h3>
            <p style={{ 
              margin: 0,
              fontSize: '14px',
              color: '#666'
            }}>
              View detailed agent performance and KYC status metrics
            </p>
          </div>
        </div>
      </div>

      <div style={{ 
        background: '#f5f5f5', 
        padding: '16px', 
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
        marginBottom: '16px'
      }}>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          marginBottom: '12px',
          alignItems: 'center'
        }}>
          <h3 style={{ margin: 0, fontSize: '16px', fontWeight: '600' }}>KYC Status Distribution</h3>
          <div style={{ 
            background: '#000', 
            color: 'white', 
            padding: '4px 10px', 
            borderRadius: '16px',
            fontSize: '13px',
            fontWeight: '500'
          }}>
            Total: {countsPending + countsHold + countsUpdateDoc + countsYtaDoc}
          </div>
        </div>

        <div style={{ 
          display: "flex", 
          width: "100%", 
          height: "32px", 
          backgroundColor: "#f8f9fa",
          borderRadius: "6px", 
          overflow: "hidden",
          position: "relative"
        }}>
          <StatusBar
            count={countsPending}
            total={countsPending + countsHold + countsUpdateDoc + countsYtaDoc}
            color="#ff9f43"
            label="Pending"
          />
          <StatusBar
            count={countsUpdateDoc}
            total={countsPending + countsHold + countsUpdateDoc + countsYtaDoc}
            color="#0abde3"
            label="Update Docs"
          />
          <StatusBar
            count={countsHold}
            total={countsPending + countsHold + countsUpdateDoc + countsYtaDoc}
            color="#feca57"
            label="On Hold"
          />
          <StatusBar
            count={countsYtaDoc}
            total={countsPending + countsHold + countsUpdateDoc + countsYtaDoc}
            color="#9b59b6"
            label="YTA"
          />
        </div>

        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-around',
          marginTop: '12px',
          flexWrap: 'wrap',
          gap: '8px'
        }}>
          <StatusLegend color="#ff9f43" label="Pending" count={countsPending} />
          <StatusLegend color="#0abde3" label="Update Documents" count={countsUpdateDoc} />
          <StatusLegend color="#feca57" label="On Hold" count={countsHold} />
          <StatusLegend color="#9b59b6" label="YTA" count={countsYtaDoc} />
        </div>
      </div>

      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        padding: '12px 16px',
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
        marginBottom: '16px',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          flex: '1 1 auto',
          minWidth: '0'
        }}>
          <div style={{ position: 'relative', flex: 1, minWidth: '200px' }}>
            <Input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search Booking Id"
              style={{
                width: '100%',
                padding: '8px 12px',
                border: '1px solid #e0e0e0',
                borderRadius: '6px',
                fontSize: '14px',
                outline: 'none',
                transition: 'border-color 0.2s',
              }}
            />
          </div>

          <Button
            type="primary"
            size="middle"
            onClick={handleSearch}
            style={{
              background: '#613AE7',
              color: 'white',
              padding: '8px 16px',
              borderRadius: '6px',
              border: 'none',
              fontSize: '14px',
              fontWeight: '500',
              cursor: 'pointer',
              transition: 'background 0.2s',
              minWidth: '80px',
              whiteSpace: 'nowrap'
            }}
          >
            Search
          </Button>

          <Button
            type="default"
            size="middle"
            onClick={fetchPaginatedData}
            style={{
              padding: '8px 16px',
              borderRadius: '6px',
              border: '1px solid #e0e0e0',
              background: 'white',
              color: '#666',
              fontSize: '14px',
              cursor: 'pointer',
              transition: 'all 0.2s',
              whiteSpace: 'nowrap'
            }}
          >
            Clear
          </Button>

          <Button
            type="default"
            size="middle"
            onClick={reset}
            style={{
              padding: '8px 16px',
              borderRadius: '6px',
              border: '1px solid #e0e0e0',
              background: 'white',
              color: '#666',
              fontSize: '14px',
              cursor: 'pointer',
              transition: 'all 0.2s',
              whiteSpace: 'nowrap'
            }}
          >
            Reset
          </Button>

          <Select
            value={filter}
            onChange={(value) => setFilter(value)}
            style={{ width: 200 }}
            size="middle"
            className="filter-select"
          >
            <Select.Option value="All">All</Select.Option>
            <Select.Option value="Pending">Pending</Select.Option>
            <Select.Option value="UpdateDocuments">Update Documents</Select.Option>
            <Select.Option value="Hold">Hold</Select.Option>
            <Select.Option value="Yta">YTA</Select.Option>
          </Select>
        </div>

        {!isSearching && (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginLeft: 'auto',
            flexShrink: 0
          }}>
            <Button
              type="link"
              size="middle"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              style={{
                padding: '8px 16px',
                borderRadius: '6px',
                border: '1px solid #e0e0e0',
                background: 'white',
                color: '#666',
                fontSize: '14px',
                cursor: 'pointer',
                transition: 'all 0.2s',
                whiteSpace: 'nowrap'
              }}
            >
              Previous
            </Button>
            <span style={{
              padding: '8px 16px',
              fontSize: '14px',
              color: '#333',
              fontWeight: '500'
            }}>
              {currentPage}
            </span>
            <Button
              type="link"
              size="middle"
              onClick={() => setCurrentPage((prev) => prev + 1)}
              style={{
                padding: '8px 16px',
                borderRadius: '6px',
                border: '1px solid #e0e0e0',
                background: 'white',
                color: '#666',
                fontSize: '14px',
                cursor: 'pointer',
                transition: 'all 0.2s',
                whiteSpace: 'nowrap'
              }}
            >
              Next
            </Button>
          </div>
        )}
      </div>
      


      <div className="overflow-y-auto h-96 border border-gray-300 rounded" style={{height:"500px"}}>
        <table className="table-fixed w-full border-collapse" style={{ fontSize: "12px", minWidth: "100%" }}>
        <thead>
            <tr style={{ backgroundColor: "grey", color: "white", fontSize: "12px", textAlign: "center", width: "100%" }}>
              <th className="px-2 py-1 border-r border-gray-300">Submission Time</th>
              <th className="px-2 py-1 border-r border-gray-300">Name</th>
              <th className="px-2 py-1 border-r border-gray-300">Country Code</th>
              <th className="px-2 py-1 border-r border-gray-300">Phone Number</th>
              <th className="px-2 py-1 border-r border-gray-300">Dial Code</th>
              <th className="px-2 py-1 border-r border-gray-300">Booking Id</th>
              {/* <th className="px-2 py-1 border-r border-gray-300">Geo Code</th> */}
              <th className="px-2 py-1 border-r border-gray-300">Submission Location</th>
              <th className="px-2 py-1 border-r border-gray-300">Activation Code</th>
              <th className="px-2 py-1 border-r border-gray-300">Category</th>
              <th className="px-2 py-1 border-r border-gray-300">Nationality</th>
              <th className="px-2 py-1 border-r border-gray-300">Passport Number</th>
              <th className="px-2 py-1 border-r border-gray-300">Status</th>
              <th className="px-2 py-1 border-r border-gray-300">Action</th>
              <th className="px-2 py-1 border-r border-gray-300">Comments</th>
              <th className="px-2 py-1">Submit</th>
              <th className="px-2 py-1">Whatsapp</th>
              <th className="px-2 py-1">Address</th>
              <th className="px-2 py-1">Action</th>
              <th className="px-2 py-1">Passport Front</th>
              <th className="px-2 py-1">Passport Back</th>
              <th className="px-2 py-1">Visa Image</th>
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
        {
          filteredData.length === 0 &&
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              fontSize: "1.2em",
              color: "#6c757d", // Optional for styling, use any color you like
            }}
          >
            No data available
          </div>
        }
      </div>

      {!isSearching && (
        <div className="mt-4 flex float-end">
          <Button
            type="link"
            size="middle"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            className=""
            style={{border: "none"}}
          >
            Previous
          </Button>
          <span className="px-4 py-2 text-sm">{currentPage}</span>
          <Button
            type="link"
            size="middle"
            onClick={() => setCurrentPage((prev) => prev + 1)}
            className=""
            style={{border: "none"}}
          >
            Next
          </Button>
        </div>
      )}

      <ToastContainer />
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {selectedImage && (
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: "Image",
                  isFluidWidth: true,
                  src: selectedImage,
                },
                largeImage: {
                  src: selectedImage,
                  width: 1200,
                  height: 1200,
                },
              }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PreKycDashboard;