import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Modal, message, Card, Space, Typography, Table, Descriptions, Tag, Row, Col } from 'antd';
import { CopyOutlined, DollarOutlined, UserOutlined, PhoneOutlined } from '@ant-design/icons';
import { ApiService } from '../../../service/ApiService';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const PaymentLinkGenerator = () => {
    const navigate = useNavigate();
  const [form] = Form.useForm();
//   const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [paymentLink, setPaymentLink] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [generatedLinks, setGeneratedLinks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchGeneratedLinks(currentPage);
  }, [currentPage]);

  const fetchGeneratedLinks = async (page) => {
    setLoading(true);
    try {
      const response = await ApiService.getAllManualPaymentLinksAdmin(page);
      console.log('Generated links:', response.data);
      setGeneratedLinks(response.data || []);
      setTotalPages(response.data.totalPages || 5);
    } catch (error) {
      console.error('Error fetching generated links:', error);
      message.error('Failed to fetch generated links');
    } finally {
      setLoading(false);
    }
  };

  const handleMobileSearch = async (value) => {
    if (!value) return;
    setSearchLoading(true);
    try {
      const response = await ApiService.getUserInfoByUuid(value);
      if (response.status === 'SUCCESS') {
        setUserDetails(response.data);
        form.setFieldsValue({ amount: '' });
      } else {
        message.error('User not found');
        setUserDetails(null);
      }
    } catch (error) {
      console.error('Error searching user:', error);
      message.error('Failed to search user');
      setUserDetails(null);
    } finally {
      setSearchLoading(false);
    }
  };

  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    const { amount } = values;
    console.log('Amount:', amount);
    if (!userDetails?.uuid) {
      message.error('Please search for a valid user first');
      return;
    }
    setLoading(true);
    try {
      const response = await ApiService.generatePaymentLinkAdmin(userDetails?.uuid, amount, navigate);
      const data = response.data;
      if (data.url) {
        setPaymentLink(data.url);
        setModalVisible(true);
        if(currentPage==1){
          fetchGeneratedLinks(1);
        }else{
          setCurrentPage(1)
        }
      } else {
        message.error('Failed to generate payment link');
      }
    } catch (error) {
      console.error('Error generating payment link:', error);
      message.error('Failed to generate payment link');
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(paymentLink)
      .then(() => message.success('Payment link copied to clipboard'))
      .catch(() => message.error('Failed to copy payment link'));
  };

  return (
    <div style={{ padding: '12px', maxWidth: '100%', margin: '0 auto', height: 'calc(100vh - 150px)' }}>
      <Row gutter={[8, 16]} style={{ height: '100%' }}>
        <Col span={8} style={{ height: '100%' }}>
          <Card
          title={"Generate Payment Link"}
            bordered={false}
            style={{
              borderRadius: '12px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              background: 'linear-gradient(145deg, #ffffff 0%, #f8fafc 100%)',
              padding: '12px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
            {/* <Title level={4} style={{ marginBottom: '12px', color: '#1a1a1a', textAlign: 'center' }}>
              Generate Payment Link
            </Title> */}
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              size="large"
              style={{ maxWidth: '500px', margin: '0 auto' }}
            >
              <Form.Item
                label="Mobile Number"
                name="mobile"
                style={{ marginBottom: '8px' }}
              >
                <Input.Search
                  prefix={<PhoneOutlined style={{ color: '#613AE7' }} />}
                  placeholder="Enter mobile number"
                  style={{ borderRadius: '8px' }}
                  loading={searchLoading}
                  onSearch={handleMobileSearch}
                />
              </Form.Item>

              {userDetails && (
                <Card
                  style={{
                    marginBottom: '12px',
                    borderRadius: '8px',
                    boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
                    padding: '8px'
                  }}
                >
                  <Typography.Title level={5} style={{ marginBottom: '24px', color: '#1a1a1a' }}>
                    User Details
                  </Typography.Title>
                  <Descriptions column={1} size="small" style={{ gap: '8px' }}>
                    <Descriptions.Item
                      label={<Typography.Text strong>Name</Typography.Text>}
                      labelStyle={{ width: '120px', paddingBottom: '12px' }}
                    >
                      <Typography.Text>{userDetails.fullName}</Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<Typography.Text strong>Email</Typography.Text>}
                      labelStyle={{ width: '120px', paddingBottom: '12px' }}
                    >
                      <Typography.Text>{userDetails.email}</Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<Typography.Text strong>KYC Status</Typography.Text>}
                      labelStyle={{ width: '120px', paddingBottom: '12px' }}
                    >
                      <Tag color={userDetails.kycStatus === 'FULL_KYC' ? 'success' : 'warning'}>
                        {userDetails.kycStatus}
                      </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<Typography.Text strong>UUID</Typography.Text>}
                      labelStyle={{ width: '120px', paddingBottom: '12px' }}
                    >
                      <Typography.Text copyable>{userDetails.uuid}</Typography.Text>
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              )}

              <Form.Item
                label="Amount"
                name="amount"
                rules={[{ required: true, message: 'Please input amount!' },
                  { pattern: /^\d+(\.\d{1,2})?$/, message: 'Please enter a valid amount!' }]}
                style={{ marginBottom: '8px' }}
              >
                <Input 
                  prefix={"Rs."}
                  placeholder="Enter amount"
                  type="number"
                  step="100"
                  style={{ borderRadius: '8px' }}
                />
              </Form.Item>

              <Form.Item>
                <Button 
                  type="primary" 
                  htmlType="submit" 
                  loading={loading}
                  style={{
                    width: '100%',
                    height: '48px',
                    borderRadius: '8px',
                    background: '#613AE7',
                    fontSize: '16px',
                    fontWeight: 500,
                    marginTop: '16px'
                  }}
                >
                  Generate Payment Link
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={16} style={{ height: '100%' }}>
          <Card
            title="Recent Payment Links"
            bordered={false}
            style={{
              borderRadius: '12px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              background: 'linear-gradient(145deg, #ffffff 0%, #f8fafc 100%)',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div style={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
              <Table
                dataSource={generatedLinks}
                rowKey="id"
                loading={loading}
                scroll={{ x: 'max-content', y: 'calc(100vh - 280px - 100px)' }}
                sticky
                style={{ flex: 1 }}
                pagination={{
                  current: currentPage,
                  total: totalPages * pageSize,
                  pageSize: pageSize,
                  onChange: (page) => setCurrentPage(page),
                  showSizeChanger: false,
                  position: ['bottomRight']
                }}
                columns={[
                  {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    width: 120,
                    render: (status) => (
                      <Tag color={status === 'PAID' ? 'success' : (status=='FAILED' ? 'error' : 'processing')}>
                        {status}
                      </Tag>
                    )
                  },
                  {
                    title: 'Amount',
                    dataIndex: 'amount',
                    key: 'amount',
                    width: 120,
                    render: (amount) => `₹${parseFloat(amount).toFixed(2)}`
                  },
                  {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    width: 200,
                    ellipsis: true
                  },
                  {
                    title: 'Phone',
                    dataIndex: 'phoneNumber',
                    key: 'phoneNumber',
                    width: 150
                  },
                  {
                    title: 'Payment Link',
                    dataIndex: 'link',
                    key: 'link',
                    width: 120,
                    render: (link) => (
                      <Button 
                        icon={<CopyOutlined />}
                        onClick={() => {
                          navigator.clipboard.writeText(link)
                            .then(() => message.success('Payment link copied to clipboard'))
                            .catch(() => message.error('Failed to copy payment link'));
                        }}
                      >
                        Copy Link
                      </Button>
                    )
                  },
                  {
                    title: 'TxnId',
                    dataIndex: 'txnId',
                    key: 'txnId',
                    width: 120,
                    render: (txnId) => (
                        <Button 
                          icon={<CopyOutlined />}
                          onClick={() => {
                            navigator.clipboard.writeText(txnId)
                              .then(() => message.success('TxnId copied to clipboard'))
                              .catch(() => message.error('Failed to copy TxnId'));
                          }}
                        >
                          Copy TxnId
                        </Button>
                      )
                  },
                  {
                    title: 'Created At',
                    dataIndex: 'createdDateTime',
                    key: 'createdDateTime',
                    width: 180,
                    render: (date) => date ? new Date(date).toLocaleString() : '-'
                  },
                  {
                    title: 'Transaction Time',
                    dataIndex: 'txnDateTime',
                    key: 'txnDateTime',
                    width: 180,
                    render: (date) => date ? new Date(date).toLocaleString() : '-'
                  }
                ]}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        title={<Title level={4} style={{ margin: 0 }}>Payment Link Generated</Title>}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        width={600}
        centered
        footer={[
          <Button 
            key="copy" 
            type="primary" 
            icon={<CopyOutlined />} 
            onClick={copyToClipboard}
            style={{ background: '#613AE7' }}
          >
            Copy Link
          </Button>,
          <Button key="close" onClick={() => {
            setModalVisible(false);
            setUserDetails(null);
            form.resetFields();
          }}>
            Close
          </Button>
        ]}
      >
        <p style={{ wordBreak: 'break-all' }}>{paymentLink}</p>
      </Modal>
    </div>
  );
};

export default PaymentLinkGenerator;