import React, { useEffect, useState } from 'react';
import { Drawer, Typography, Space, Button, Input as AntInput, Card, Avatar, Descriptions, Image, Divider, Steps } from 'antd';
import { styles } from './styles';
import { getFormattedDateTime } from '../../../service/codeUtils';
import { auditorKycSubmit, getKycStepsData } from '../../../redux/thunks/kyc.thunk';
import { APICallStatus, KycAction } from '../../../constants';
import { showErrorToast, showSuccessToast } from '../../../service/toast';
import { UserOutlined } from '@ant-design/icons';
import { theme } from '../../../constants/theme';

const { Title, Text } = Typography;

const stepsInitState = [
  { title: 'KYC Submission Timestamp', 
    key:"kycSubmissionTimestamp",
    description:'' },
  {
    title: 'Agent Assignment Timestamp',
    key:"agentAssignmentTimestamp",
    description : '',
  },
  {
    title: 'Agent Action Timestamp',
    key:"auditorAssignmentTimestamp",
    description : '',
  },
  {
    title: 'Auditor Assignment Timestamp',
    key:"agentActionTimestamp",
    description : '',
  },
  {
    title: 'Auditor Action Timestamp',
    key:"auditorActionTimestamp",
    description : '',
  },
];

export default function AuditorUserDetailsModal({
  visible,
  userDetails,
  onClose,
  isActionableModal,
}) {
  const [agentRemarks, setAgentRemarks] = useState('');
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);
  const [stepsState, setStepsState] = useState(stepsInitState);
  const [kycCurrentStep, setKycCurrentStep] = useState(0);

  useEffect(() =>{
    setKycCurrentStep(0)
    setStepsState(stepsInitState)
    if(userDetails?.bookingId){
      // console.log("ssacmmzc", userDetails?.bookingId);
      
      const getKycSteps =async()=>{
        const kycStepsData = await getKycStepsData(userDetails?.bookingId);
          // console.log("xkkxkkkxx", kycStepsData?.data?.data);
          let newStepsState = [...stepsInitState];
          let kycCurrentStepLocal = kycCurrentStep;
          for (const [key, value] of Object.entries(kycStepsData?.data?.data)) {
            // console.log(`${key}: ${value}`);
            let obj = newStepsState.find(o => o.key === key);
            if(obj){
              if(value!=null){
                kycCurrentStepLocal++
                obj.description = new Date(Number(value)).toLocaleString();
              }else{
                obj.description = "Yet to be done"
              }
            }
          }
          // console.log("sdjknkncsk", newStepsState)
          setStepsState(newStepsState);
          setKycCurrentStep(kycCurrentStepLocal)
      }
      getKycSteps()
    }
  },[userDetails?.bookingId])


  const onSubmit = async (action) => {
    if (action === KycAction.APPROVE) {
      setIsLoadingApprove(true);
    } else {
      setIsLoadingReject(true);
    }
    const response = await auditorKycSubmit({
      bookingId: userDetails?.bookingId,
      action,
      comments: agentRemarks
    });
    setIsLoadingApprove(false);
    setIsLoadingReject(false);
    if (response?.status === APICallStatus.SUCCESS) {
      onClose();
      showSuccessToast("User approved successfully!!")
    } else {
      showErrorToast(response?.message);
    }
  };

  const kycSteps = () => {
    return (
      <Steps
        direction="vertical"
        size="small"
        current={kycCurrentStep}
        items={stepsState}
      />
    )
  }

  return (
    <Drawer 
      open={visible} 
      onClose={onClose} 
      width={800}
      placement="right"
      title={
        <Space>
          <UserOutlined />
          <Typography.Title level={4} style={{ margin: 0 }}>
            User Details
          </Typography.Title>
        </Space>
      }
      extra={
        isActionableModal && (
          <Space>
            <Button 
              danger 
              type="primary" 
              loading={isLoadingReject}
              onClick={() => onSubmit(KycAction.REJECT)}
              disabled={!agentRemarks?.trim()}
            >
              Reject
            </Button>
            <Button 
              type="primary" 
              loading={isLoadingApprove}
              onClick={() => onSubmit(KycAction.APPROVE)}
              disabled={!agentRemarks?.trim()}
            >
              Approve
            </Button>
          </Space>
        )
      }
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {/* User Profile Section */}
        <Card>
          <Space align="center" style={{ width: '100%', justifyContent: 'start' }}>
            {userDetails?.userProfilePhoto ? (
              // <Avatar 
              //   size={100} 
              //   src={userDetails.userProfilePhoto} 
              // />
              <Avatar size={30} style={{backgroundColor:theme.COLOR.ThemePrimary}} icon={<UserOutlined />} />
            ) : (
              <Avatar size={30} style={{backgroundColor:theme.COLOR.ThemePrimary}} icon={<UserOutlined />} />
            )}
            <Space direction="vertical" align="center">
              <Title level={4} style={{ margin: 0 }}>{userDetails?.userName}</Title>
              <Text type="secondary">{userDetails?.userCountryName}</Text>
            </Space>
          </Space>
        </Card>
        <Card title="KYC Journey">
          <Space align="center" style={{ width: '100%', justifyContent: 'start' }}>
        {kycSteps()}
        </Space>
        </Card>

        {/* Contact Information */}
        <Card title="Contact Information">
          <Descriptions column={2}>
            <Descriptions.Item label="Phone Number">{userDetails?.userPhoneNumber}</Descriptions.Item>
            <Descriptions.Item label="Alternate Number">{userDetails?.alternatePhoneNumber || '--'}</Descriptions.Item>
            <Descriptions.Item label="Address" span={2}>
              {`${userDetails?.addressLine1}, ${userDetails?.addressLine2}, ${userDetails?.city}, ${userDetails?.state} ${userDetails?.pincode}`}
            </Descriptions.Item>
          </Descriptions>
        </Card>

        {/* Passport Details */}
        <Card title="Passport Details">
          <Space direction="horizontal" size="middle" style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
            <div>
              <Text strong>Passport Front Image</Text>
              <div style={{ position: 'relative', width: 200, height: 150, marginTop: 8, backgroundColor: '#f0f2f5', borderRadius: 8, overflow: 'hidden' }}>
                {userDetails?.passportFrontImage && userDetails?.passportFrontImage !== "Not Provided" ? (
                  <Image
                    width={200}
                    height={150}
                    src={userDetails?.passportFrontImage}
                    style={{ objectFit: 'cover' }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?v=1530129081";
                    }}
                    preview={{
                      mask: (
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#fff',
                          backgroundColor: 'rgba(0, 0, 0, 0.45)',
                          padding: 10
                        }}>
                          <div style={{ fontSize: 14 }}>View Image</div>
                        </div>
                      )
                    }}
                  />
                ) : (
                  <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#f8f8f8',
                    color: '#999',
                    fontSize: '13px',
                    cursor: 'default',
                    userSelect: 'none'
                  }}>
                    No Image Available
                  </div>
                )}
              </div>
            </div>
            {userDetails?.passportBackImage && (
              <div>
                <Text strong>Passport Back Image</Text>
                <div style={{ position: 'relative', width: 200, height: 150, marginTop: 8, backgroundColor: '#f0f2f5', borderRadius: 8, overflow: 'hidden' }}>
                  <Image
                    width={200}
                    height={150}
                    src={userDetails?.passportBackImage}
                    style={{ objectFit: 'cover' }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?v=1530129081";
                    }}
                    preview={{
                      mask: (
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#fff',
                          backgroundColor: 'rgba(0, 0, 0, 0.45)',
                          padding: 10
                        }}>
                          <div style={{ fontSize: 14 }}>View Image</div>
                        </div>
                      )
                    }}
                  />
                </div>
              </div>
            )}
          </Space>
        </Card>

        {/* Visa Details */}
        <Card title="Visa Details">
          <Descriptions column={2}>
            <Descriptions.Item label="Start Date">
              {getFormattedDateTime(userDetails?.visaStartDate)?.split('|')[0]}
            </Descriptions.Item>
            <Descriptions.Item label="Expiry Date">
              {getFormattedDateTime(userDetails?.visaEndDate)?.split('|')[0]}
            </Descriptions.Item>
          </Descriptions>
          <div style={{ marginTop: 16 }}>
            <Text strong>Visa Image</Text>
            <div style={{ position: 'relative', width: 200, height: 150, marginTop: 8, backgroundColor: '#f0f2f5', borderRadius: 8, overflow: 'hidden' }}>
              {userDetails?.visaImage && userDetails?.visaImage !== "Not Provided" ? (
                <Image
                  width={200}
                  height={150}
                  src={userDetails?.visaImage}
                  style={{ objectFit: 'cover' }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?v=1530129081";
                  }}
                  preview={{
                    mask: (
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                        backgroundColor: 'rgba(0, 0, 0, 0.45)',
                        padding: 10
                      }}>
                        <div style={{ fontSize: 14 }}>View Image</div>
                      </div>
                    )
                  }}
                />
              ) : (
                <div style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#f8f8f8',
                  color: '#999',
                  fontSize: '13px',
                  cursor: 'default',
                  userSelect: 'none'
                }}>
                  No Image Available
                </div>
              )}
            </div>
          </div>
        </Card>

        {/* Agent Comments */}
        <Card title="Comments">
          <Space direction="vertical" style={{ width: '100%' }}>
            <div>
              <Text strong underline>Agent</Text><br></br><br></br>
              <Text strong>Comments : </Text>
              <Text mark>{userDetails?.agentComments || '--'}</Text>
              <div>
                <Text strong>Timestamp : </Text>
                <Text>{userDetails?.agentActionTimestamp ? new Date(Number(userDetails.agentActionTimestamp)).toLocaleString() : "N/A"}</Text>
              </div>
              <div>
                <Text strong>Geocode : </Text>
                <Text>{userDetails?.agentLatitude ? parseFloat(userDetails.agentLatitude).toFixed(2) : "N/A"}</Text>,
                <Text>{userDetails?.agentLongitude ? parseFloat(userDetails.agentLongitude).toFixed(2) : "N/A"}</Text>
              </div>
              {!isActionableModal && <><Divider />
              <Text underline strong>Auditor</Text><br></br><br></br>
              <Text strong>Comments : </Text>
              <Text mark>{userDetails?.auditorComments || '--'}</Text>
              <div>
                <Text strong>Timestamp : </Text>
                <Text>{userDetails?.auditorActionTimestamp ? new Date(Number(userDetails.auditorActionTimestamp)).toLocaleString() : "N/A"}</Text>
              </div></>}
            </div>
            {isActionableModal && (
              <div>
                <Text strong>Your Remarks</Text>
                <AntInput.TextArea
                  value={agentRemarks}
                  onChange={(e) => setAgentRemarks(e.target.value)}
                  rows={4}
                  style={{ marginTop: 8 }}
                />
              </div>
            )}
          </Space>
        </Card>
      </Space>
    </Drawer>
  );
}
