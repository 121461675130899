import { APICallStatus } from "../../constants";
import { Config } from "../../constants/config";
import { ApiService } from "../../service/ApiService";
import { message } from 'antd';

export const fetchClosureTickets = async (navigate) => {
  try {
    const response = await ApiService.postApi(Config.apis.accounts.closureTickets, {
    });
    console.log(response);
    return {
      status: APICallStatus.SUCCESS,
      tickets: response?.data?.data,
    };
  } catch (error) {
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage
    };
  }
}

export const fetchClosureWalletBalance = async (uuid, navigate) => {

  console.log("uuid", uuid);
  try {
    const response = await ApiService.postApi(Config.apis.accounts.walletBalance, {
      uuid: uuid,
    });
    console.log(response);
    return {
      status: APICallStatus.SUCCESS,
      data: response?.data?.data,
    };
  } catch (error) {
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage,
    };
  }
}

export const actionClosureRequest = async ({uuid, ticketId, action, comment}) => {
  try {
    const response = await ApiService.postApi(Config.apis.accounts.closeRequest, {
      uuid: uuid,
      ticketId: ticketId,
      action: action,
      comment: comment
    });
    console.log(response);
    return {
      status: APICallStatus.SUCCESS,
    };
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage
    };
  }
}

export const initiateTransactionRefund = async (id,navigate) => {
  try {
    const response = await ApiService.postApi(Config.apis.accounts.initiateTxnRefund, {
      id: id,
    });
    return {
      status: APICallStatus.SUCCESS
    };
  } catch (error) {
    // console.log("lslslsls", error?.data)
    if (error?.response?.status === 401) {
      message.error('Session expired. Redirecting to login page.');
      navigate('/login'); // Redirect to the login page
    }
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage
    };
  }
}