import { AccessRoles, APICallStatus } from "../../constants";
import { Config } from "../../constants/config";
import { setLoggedInUser } from "../../redux/actions/app";
import store from "../../redux/store";
import { saveTokens } from "../../redux/thunks/auth.thunk";
import { ApiService } from "../ApiService";
import { isCSAAgent } from "../codeUtils";
import { StorageService } from "../StorageService";


export const sendLoginOtp = async (countryCode,phoneNumber, role) => {
  try {
    console.log(process.env);
    const response = await ApiService.postApi(Config.apis.auth.login, {
      phoneNumber: `${countryCode.dialCode}${phoneNumber}`,
      countryCode: countryCode.countryCode,
      dialCode: `+${countryCode.dialCode}`,
      role,
    });
    console.log(response);
    return {
      status: APICallStatus.SUCCESS,
      verificationId: response?.data?.verificationId,
    };
  } catch (error) {
    console.log(error?.response?.data?.error?.shortMessage);
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong',
    };
  }
}

export const verifyLoginOtp = async (selectedCountryCode, phoneNumber, otp, role) => {
  try {
    console.log(process.env);
    const response = await ApiService.postApi(Config.apis.auth.verifyOtp, {
      phoneNumber: `${selectedCountryCode.dialCode}${phoneNumber}`,
      countryCode: selectedCountryCode.countryCode,
      dialCode: `+${selectedCountryCode.dialCode}`,
      otp,
      role
    });
    console.log('Verify OTP Response:', response);
    if (response?.data?.data?.accessToken) {
      await saveTokens({
        accessToken: response?.data?.data?.accessToken,
        refreshToken: response?.data?.data?.refreshToken,
      });
      return {
        status: APICallStatus.SUCCESS,
      };
    }
    return {
      status: APICallStatus.ERROR,
      message: 'Invalid response from server'
    };
  } catch (error) {
    console.log('Verify OTP Error:', error);
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage || 'Invalid OTP',
    };
  }
};

export const isTokenValid = async () => {
  try {
    const accessToken = await StorageService.getAccessToken();
    const refreshToken = await StorageService.getRefreshToken();
    saveTokens({
      accessToken,
      refreshToken,
    });
    const response = await ApiService.postApi(Config.apis.auth.validateToken, {});
    return {
      status: response?.data?.status === "SUCCESS" ? APICallStatus.SUCCESS : APICallStatus.ERROR,
    };
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong',
    };
  }
}

export const fetchLoggedInProfile = async () => {
  try {
    const dispatch = store.dispatch;
    const response = await ApiService.postApi(Config.apis.auth.fetchLoggedInUser, {});
    console.log('Fetch Profile Response:', response);
    if (response?.data?.data) {
      dispatch(setLoggedInUser({
        ...response?.data?.data,
        userRole: response?.data?.data?.userRole,
      }));
      return {
        status: APICallStatus.SUCCESS,
        data: response?.data?.data
      };
    }
    return {
      status: APICallStatus.ERROR,
      message: 'Failed to fetch user profile'
    };
  } catch (error) {
    console.error('Fetch Profile Error:', error);
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage || 'Failed to fetch user profile'
    };
  }
};