import { theme } from "../../../constants/theme";
import layout from "../../../constants/layout";

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#fff'
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: layout.window.toNormH(20)
  },
  statusText: {
    fontSize: layout.window.toNormH(24),
    fontWeight: '700',
    marginTop: layout.window.toNormH(10),
    color: theme.COLOR.ThemePrimary,
    width: layout.window.toNormW(700),
    textAlign: 'center'
  },
  statusSubText: {
    fontSize: layout.window.toNormH(16),
    fontWeight: '500',
    marginTop: layout.window.toNormH(10),
    color: theme.COLOR.Grey100,
    width: layout.window.toNormW(500),
    textAlign: 'justify'
  },
  successIcon: {
    color: theme.COLOR.SuccessGreen,
    fontSize: layout.window.toNormH(50)
  },
  failureIcon: {
    color: theme.COLOR.Red,
    fontSize: layout.window.toNormH(50)
  }
}