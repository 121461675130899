import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { theme } from "../../constants/theme";
import { getPlatform } from "../../constants/codeUtils";
import { saveTokens } from "../../redux/thunks/auth.thunk";

const styles = {
  headerNavBar: {
    position: 'fixed',
    padding: '12px 24px',
    paddingTop: getPlatform() === 'iOS' ? '62px' : '12px',
    backgroundColor: 'white',
    width: '100%',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.06)',
    zIndex: 10,
    borderBottom: '1px solid #f0f0f0'
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // maxWidth: '1400px',
    paddingLeft: '210px',
    margin: '0 auto',
    width: '100%'
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px'
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'all 0.2s',
    color: '#666',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  title: {
    fontSize: '18px',
    fontWeight: '600',
    color: '#333',
    margin: 0
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px'
  },
  userName: {
    margin: 0,
    fontSize: '14px',
    color: '#666',
    fontWeight: '500'
  },
  logoutButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 16px',
    borderRadius: '6px',
    cursor: 'pointer',
    transition: 'all 0.2s',
    color: theme.COLOR.Red,
    border: '1px solid ' + theme.COLOR.Red,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#fff1f0'
    }
  },
  spacer: {
    height: getPlatform() === 'iOS' ? '110px' : '60px',
    width: '100%'
  }
};

function Header({ title, showBackButton, name }) {
  const navigate = useNavigate();

  const logout = () => {
    saveTokens({
      accessToken: '',
      refreshToken: '',
    });
    navigate('/');
  }

  return (
    <>
      <header style={styles.headerNavBar}>
        <div style={styles.headerContent}>
          <div style={styles.leftSection}>
            {showBackButton && (
              <button
                style={styles.backButton}
                onClick={() => navigate(-1)}
                title="Go back"
              >
                <FontAwesomeIcon icon={faArrowLeft} size="lg" />
              </button>
            )}
            {title && (
              <h1
                id={`nav-title-${title?.toLowerCase().split(' ').join('-')}`}
                style={styles.title}
              >
                {title}
              </h1>
            )}
          </div>

          <div style={styles.rightSection}>
            {name && <p style={styles.userName}>{name}</p>}
            <button 
              style={styles.logoutButton}
              onClick={logout}
              title="Logout"
            >
              <FontAwesomeIcon
                icon={faRightFromBracket}
                size="sm"
              />
              <span style={{ fontWeight: "500" }}>Logout</span>
            </button>
          </div>
        </div>
      </header>
      <div style={styles.spacer} />
    </>
  );
}

export default Header;
