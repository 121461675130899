import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Card, Form, Input, Button, Select, Typography, Space, Divider, Layout } from 'antd';
import { UserOutlined, LockOutlined, GlobalOutlined } from '@ant-design/icons';
import { fetchLoggedInProfile, sendLoginOtp, verifyLoginOtp } from "../../../service/AuthService";
import { APICallStatus, AccessRoles } from "../../../constants";
import { showErrorToast,showSuccessToast } from "../../../service/toast";
import MonyLogo from '../../../assets/images/monyLogo.png';
import { isCSAAgent } from "../../../service/codeUtils";
const { Header, Content, Footer } = Layout;

const { Title, Text } = Typography;
const { Option } = Select;

function WebLogin() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  
  const roles = [
    {roleKey: AccessRoles.ADMIN, roleName: 'Admin'},
    {roleKey: AccessRoles.BACKOFFICE_ADMIN, roleName: 'Back Office Admin'},
    {roleKey: AccessRoles.CSA, roleName: 'Customer Service Agent'},
    {roleKey: AccessRoles.AUDITOR, roleName: 'Auditor'},
  ];

  const countryCodes = [
    {countryCode: "IN", dialCode: '91'},
    {countryCode: "AE", dialCode: '971'},
  ];

  const [selectedRole, setSelectedRole] = useState(roles[0]);
  const [selectedCountryCode, setSelectedCountryCode] = useState(countryCodes[0]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disableOtpRequest, setDisableOtpRequest] = useState(false);
  const [timer, setTimer] = useState(59);
  const [requestOtpClick, setRequestOtpClicked] = useState(false);

  const sendOtp = async () => {
    if (!phoneNumber) {
      showErrorToast('Please enter your phone number');
      return;
    }
    
    console.log('Sending OTP with:', {
      dialCode: selectedCountryCode.dialCode,
      phoneNumber,
      role: selectedRole.roleKey
    });
    
    setIsLoading(true);
    setDisableOtpRequest(true);
    
    try {
      const dialCode = `+${selectedCountryCode.dialCode}`;
      console.log('Formatted dialCode:', dialCode);
      
      const response = await sendLoginOtp(
        selectedCountryCode,
        phoneNumber, 
        selectedRole.roleKey
      );
      if (response?.status === APICallStatus.SUCCESS) {
        const interval = setInterval(() => {
          setTimer((prevTimer) => {
            if (prevTimer <= 0) {
              setDisableOtpRequest(false);
              clearInterval(interval);
              return 59;
            }
            return prevTimer - 1;
          });
        }, 1000);
        showSuccessToast("OTP sent successfully.")
        setRequestOtpClicked(true);
      } else {
        setDisableOtpRequest(false);
        showErrorToast(response?.message || 'Failed to send OTP');
      }
    } catch (error) {
      setDisableOtpRequest(false);
      showErrorToast('Failed to send OTP. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOtp = async () => {
    if (!otp) {
      showErrorToast('Please enter OTP');
      return;
    }
    setIsLoading(true);
    
    try {
      console.log('Verifying OTP with:', {
        countryCode: selectedCountryCode,
        phoneNumber,
        otp,
        role: selectedRole.roleKey
      });

      const response = await verifyLoginOtp(
        selectedCountryCode, 
        phoneNumber, 
        otp, 
        selectedRole.roleKey
      );

      console.log('Verify OTP Response:', response);

      if (response?.status === APICallStatus.SUCCESS) {
        try {
          const userResponse = await fetchLoggedInProfile();
          console.log('User Profile Response:', userResponse);
          
          if (userResponse?.status === APICallStatus.SUCCESS) {
            navigate('/dashboard', { replace: true });
          } else {
            showErrorToast(userResponse?.message || 'Failed to fetch user profile');
          }
        } catch (profileError) {
          console.error('Profile Error:', profileError);
          showErrorToast('Failed to fetch user profile');
        }
      } else {
        showErrorToast(response?.message || 'Invalid OTP');
      }
    } catch (error) {
      console.error('Login Error:', error);
      showErrorToast('Failed to verify OTP. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ 
      minHeight: '100vh', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      background: 'linear-gradient(135deg, #613AE7 0%, #7E5AF0 100%)'
    }}>
      <Card 
        style={{ 
          width: 400, 
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          background: 'rgba(255, 255, 255, 0.95)'
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: 24 }}>
          <img src={MonyLogo} alt="Mony Logo" style={{ height: 40, marginBottom: 16 }} />
          <Title level={3} style={{ color: '#613AE7', marginBottom: 8 }}>Welcome Back</Title>
          <Text type="secondary">Sign in to continue to your account</Text>
        </div>

        <Form
          form={form}
          layout="vertical"
          onFinish={requestOtpClick ? verifyOtp : sendOtp}
        >
          <Form.Item label="Role">
            <Select
              value={selectedRole.roleKey}
              onChange={(value) => setSelectedRole(roles.find(role => role.roleKey === value))}
              style={{ width: '100%' }}
            >
              {roles.map(role => (
                <Option key={role.roleKey} value={role.roleKey}>{role.roleName}</Option>
              ))}
            </Select>
          </Form.Item>

          <Space.Compact style={{ width: '100%' }}>
            <Select
              value={selectedCountryCode.dialCode}
              onChange={(value) => {
                const code = countryCodes.find(code => code.dialCode === value);
                console.log('Selected country code:', code);
                setSelectedCountryCode(code);
              }}
              style={{ width: '30%' }}
            >
              {countryCodes.map(code => (
                <Option key={code.countryCode} value={code.dialCode}>
                  +{code.dialCode}
                </Option>
              ))}
            </Select>
            <Form.Item 
              style={{ width: '70%', marginBottom: 0 }}
              rules={[{ required: true, message: 'Please enter your phone number' }]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Item>
          </Space.Compact>

          {requestOtpClick && (
            <Form.Item
              style={{ marginTop: 16 }}
              rules={[{ required: true, message: 'Please enter OTP' }]}
            >
              <Input
                prefix={<LockOutlined />}
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </Form.Item>
          )}

          <Form.Item style={{ marginTop: 24 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              block
              style={{ 
                height: 40,
                background: '#613AE7',
                borderColor: '#613AE7'
              }}
            >
              {requestOtpClick ? 'Verify OTP' : 'Send OTP'}
            </Button>
          </Form.Item>

          {disableOtpRequest && (
            <Text type="secondary" style={{ display: 'block', textAlign: 'center' }}>
              Resend OTP in {timer}s
            </Text>
          )}
          {requestOtpClick && <Text type="secondary" style={{ display: 'block', textAlign: 'center' }}>
             Please check OTP on your registered phone number and email ID.
            </Text>}
        </Form>
      </Card>
      <Footer style={{ position: 'fixed', bottom: 0, left: 0, right: 0, textAlign: 'center', padding: '20px', backgroundColor: '#f0f2f5' }}>
        Made with 💜 in Bangalore - &copy; Copyright 2025, All Rights Reserved by Mony
      </Footer>
    </div>
  );
}

export default WebLogin;
