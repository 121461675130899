import { useNavigate } from "react-router-dom";
import { styles } from './styles';
import { theme } from "../../../constants/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { APICallStatus, KycAction } from "../../../constants";
import { fetchAuditorKycUserList, fetchUserKycData, auditorKycSubmit } from "../../../redux/thunks/kyc.thunk";
import layout from "../../../constants/layout";
import { showErrorToast, showInfoToast } from "../../../service/toast";
// import Input from "../../../elements/Input";
import CustomButton from "../../../elements/CustomButton";
import { getFormattedDateTime } from "../../../service/codeUtils";
import { Button, Layout, Menu, Input, Card, Typography } from 'antd';
import { DashboardOutlined, CheckCircleOutlined } from '@ant-design/icons';
import AuditorUserDetailsModal from '../../modals/AuditorUserDetailsModal';

const { Header, Content, Sider, Footer } = Layout;

function AuditorDashboard() {
  const options = {
    PENDING_APPROVALS: 'PENDING_APPROVALS',
    APPROVED: 'APPROVED'
  }
  
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [modalUserData, setModalUserData] = useState();
  const [selectedOption, setSelectedOption] = useState(options.PENDING_APPROVALS);
  const [searchQueryText, setSearchQueryText] = useState('');
  const [agentRemarks, setAgentRemarks] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const selectMenu = (key) => {
    setSelectedOption(key);
    setSearchQueryText('')
  }

  const onSubmit = async (action) => {
    setIsLoading(true);
    const response = await auditorKycSubmit({
      bookingId: modalUserData?.bookingId,
      action,
      comments: agentRemarks
    });
    setIsLoading(false);
    if(response?.status === APICallStatus.SUCCESS) {
      handleModalClose();
    } else {
      showErrorToast(response?.message);
    }
  }

  const handleModalClose = () => {
    onLoad();
    setModalUserData(null);
    setAgentRemarks('');
    setSearchQueryText('')
  }

  const searchUserData = async () => {
    setIsLoading(true);
    const response = await fetchUserKycData({
      query: searchQueryText,
    });
    setIsLoading(false);
    console.log(response);
    if(response?.status === APICallStatus.SUCCESS) {
      setModalUserData(response?.userData);
    }
  }

  const menuItems = [
    {
      key: options.PENDING_APPROVALS,
      label: 'Pending Approvals',
      icon: <DashboardOutlined />
    },
    {
      key: options.APPROVED,
      label: 'Approved',
      icon: <CheckCircleOutlined />
    }
  ];

  const onLoad = async () => {
    const response = await fetchAuditorKycUserList();
    if(response?.status === APICallStatus.SUCCESS) {
      setUsers(response?.usersList);
    }
  }

  useEffect(() => {
    onLoad();
  }, []);

  
 
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        collapsedWidth={0}
        breakpoint='lg'
        theme="dark"
        style={{
          position: 'fixed',
          height: '100vh',
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 999,
          backgroundColor: '#000000',
          overflow: 'hidden'
        }}
      >
        <div className='logo'>
          <img
            src="https://havemony.com/mony_logo.svg"
            alt="Mony Logo"
            style={{
              width: '120px',
              height: 'auto',
              margin: '16px auto',
              display: 'block'
            }}
          />
        </div>
        <Menu
          theme='dark'
          mode='inline'
          defaultSelectedKeys={[selectedOption]}
          selectedKeys={[selectedOption]}
          onSelect={({ key }) => selectMenu(key)}
          style={{
            backgroundColor: '#000000',
            borderRight: 0
          }}
        >
          {menuItems.map(item => (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              style={{
                backgroundColor: selectedOption === item.key ? '#613AE7' : 'transparent'
              }}
            >
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout style={{ marginLeft: 200 }}>
        <Content style={{ padding: '24px' }}>
          {selectedOption === options.PENDING_APPROVALS ? (
            <div style={{ padding: '24px' }}>
              <div style={{
                backgroundColor: '#fff',
                borderRadius: '12px',
                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                overflow: 'hidden'
              }}>
                <div style={{
                  padding: '16px 24px',
                  borderBottom: '1px solid #e5e7eb',
                  backgroundColor: '#f8fafc'
                }}>
                  <h2 style={{ margin: 0, fontSize: '18px', fontWeight: '600', color: '#1f2937' }}>Pending Approvals</h2>
                </div>
                <div style={{ padding: '0 24px' }}>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr style={{ borderBottom: '1px solid #e5e7eb' }}>
                        <th style={{ padding: '12px 8px', textAlign: 'left', fontSize: '14px', fontWeight: '500', color: '#6b7280' }}>Full Name</th>
                        <th style={{ padding: '12px 8px', textAlign: 'left', fontSize: '14px', fontWeight: '500', color: '#6b7280' }}>Mobile Number</th>
                        <th style={{ padding: '12px 8px', textAlign: 'left', fontSize: '14px', fontWeight: '500', color: '#6b7280' }}>Country</th>
                        <th style={{ padding: '12px 8px', textAlign: 'left', fontSize: '14px', fontWeight: '500', color: '#6b7280' }}>Submitted Date</th>
                        <th style={{ padding: '12px 8px', textAlign: 'center', fontSize: '14px', fontWeight: '500', color: '#6b7280' }}>Agent Approval</th>
                        <th style={{ padding: '12px 8px', width: '48px' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {users?.length > 0 ? users.map(user => (
                        <tr 
                          key={user.bookingId}
                          onClick={() => setModalUserData(user)}
                          style={{ 
                            borderBottom: '1px solid #e5e7eb',
                            cursor: 'pointer',
                            transition: 'background-color 0.2s',
                            ':hover': { backgroundColor: '#f9fafb' }
                          }}
                        >
                          <td style={{ padding: '16px 8px', fontSize: '14px', color: '#1f2937' }}>{user?.userName}</td>
                          <td style={{ padding: '16px 8px', fontSize: '14px', color: '#1f2937' }}>{user?.userPhoneNumber}</td>
                          <td style={{ padding: '16px 8px', fontSize: '14px', color: '#1f2937' }}>{user?.userCountryName}</td>
                          <td style={{ padding: '16px 8px', fontSize: '14px', color: '#1f2937' }}>{getFormattedDateTime(user?.preferredDateTime)}</td>
                          <td style={{ padding: '16px 8px', textAlign: 'center' }}>
                            <FontAwesomeIcon icon={faCheckSquare} color={theme.COLOR.SuccessGreen} fontSize={16}/>
                          </td>
                          <td style={{ padding: '16px 8px', textAlign: 'center' }}>
                            <FontAwesomeIcon icon={faChevronCircleRight} color={theme.COLOR.ThemePrimary} fontSize={16}/>
                          </td>
                        </tr>
                      )) : (
                        <tr>
                          <td colSpan="6" style={{ padding: '48px 0', textAlign: 'center' }}>
                            <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '24px', color: '#9ca3af', marginBottom: '8px' }}/>
                            <p style={{ margin: 0, color: '#6b7280', fontSize: '14px' }}>No records found</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : selectedOption === options.APPROVED ? (
            <Card className="d-flex flex-column" 
              title={
                <Typography.Title level={4} style={{ margin: 0 }}>
                 Search Approved Users
                </Typography.Title>
              }
              style={{
                width: 400,
                boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                borderRadius: '8px'
            }}>
              <div style={{
                position: 'relative',
                marginBottom: '24px'
              }}>
                <Input
                  size="large"
                  allowClear={true}
                  onPressEnter={searchUserData}
                  placeholder="Search by UUID or Phone Number"
                  value={searchQueryText}
                  onChange={(e)=>setSearchQueryText(e.target.value)}
                />
                {/* <Input size="large" placeholder="large size" prefix={<UserOutlined />} /> */}
              </div>
              <Button
                      type="primary" 
                      style={{ 
                        backgroundColor: '#613AE7',
                        height: '40px',
                        width:"100%"
                      }}
                      loading={isLoading}
                      onClick={searchUserData}
                    >
                      Search
                    </Button>
            </Card>
          ) : null}
        </Content>
        <Footer style={{ position: 'fixed', bottom: 0, left: 0, right: 0, textAlign: 'center', padding: '20px', backgroundColor: '#f0f2f5' }}>
          Made with 💜 in Bangalore - Copyright 2025, All Rights Reserved by Mony
        </Footer>
      </Layout>
      <AuditorUserDetailsModal
        visible={modalUserData}
        userDetails={modalUserData}
        onClose={handleModalClose}
        isActionableModal={selectedOption === options.PENDING_APPROVALS}
      />
    </Layout>
  );
}

export default AuditorDashboard;
