import React, { useEffect, useState } from 'react'
import { styles } from './styles'
import { fetchAdminUnmappedKycUsers, fetchAuditorKycUserList } from '../../../redux/thunks/kyc.thunk';
import { APICallStatus } from '../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faChevronCircleRight, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../../constants/theme';
import layout from '../../../constants/layout';
import { getFormattedDateTime } from '../../../service/codeUtils';
import AgentUserMapModal from '../../modals/AgentUserMapModal';
import ClosureDetailsModal from '../../modals/ClosureDetailsModal';
import { fetchClosureTickets } from '../../../redux/thunks/account.thunk';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function ClosureRequests() {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [modalUserData, setModalUserData] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Loading...');
  const [loadingTimer, setLoadingTimer] = useState(null);

  const onLoad = async () => {
    setLoading(true);
    setLoadingText('Loading...');
    
    // Set timer for 7 seconds
    const timer = setTimeout(() => {
      setLoadingText('Loading is taking longer than expected. Please wait...');
    }, 7000);
    setLoadingTimer(timer);

    try {
      const response = await fetchClosureTickets(navigate);
      console.log(response);
      if(response?.status === APICallStatus.SUCCESS) {
        setTickets(response?.tickets);
      }
    } finally {
      setLoading(false);
      clearTimeout(loadingTimer);
    }
  }

  useEffect(() => {
    if(tickets?.length === 0)
      onLoad();
    // Cleanup timer on component unmount
    return () => {
      if (loadingTimer) {
        clearTimeout(loadingTimer);
      }
    };
  }, []);

  const onCloseModal = () => {
    onLoad();
    setModalUserData(null);
  }

  if (loading) {
    return (
      <div style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '400px',
        gap: '16px'
      }}>
        <Spin size="large" />
        <div style={{ 
          marginTop: '16px',
          color: '#666',
          fontSize: '14px',
          textAlign: 'center'
        }}>
          {loadingText}
        </div>
      </div>
    );
  }

  return (
    <div className='d-flex flex-column' style={styles.container}>
      <div className="d-flex flex-row justify-space-between" style={styles.tableHeader}>

        <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
          <p style={styles.tableHeaderText}>Full Name</p>
        </div>

        <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
          <p style={styles.tableHeaderText}>Mobile Number</p>
        </div>

        <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
          <p style={styles.tableHeaderText}>Country</p>
        </div>

        <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
          <p style={styles.tableHeaderText}>Request date & time</p>
        </div>

        <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
          <p style={styles.tableHeaderText}>Visa expiry</p>
        </div>

        <div className="d-flex flex-row justify-content-center" style={styles.tableRowLastItem}>
        </div>

      </div>

      <div className="d-flex flex-column" style={styles.tableRowsContainer}>
        {tickets?.length > 0 ? tickets?.map(ticket => <div className="d-flex flex-row justify-space-between" style={styles.tableRow} onClick={() => setModalUserData(ticket)}>
          <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
            <p style={styles.tableHeaderText}>{ticket?.userInfoDetails?.fullName}</p>
          </div>

          <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
            <p style={styles.tableHeaderText}>{ticket?.userDetails?.phoneNumber}</p>
          </div>

          <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
            <p style={styles.tableHeaderText}>{ticket?.userInfoDetails?.countryName}</p>
          </div>

          <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
            <p style={styles.tableHeaderText}>{getFormattedDateTime(ticket?.createdTimestamp)}</p>
          </div>

          <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
            {/* <p style={styles.tableHeaderText}>{user?.city}, {user?.state}</p> */}
          </div>

          <div className="d-flex flex-row justify-content-center align-items-center" style={styles.tableRowLastItem}>
            <FontAwesomeIcon icon={faChevronCircleRight} color={theme.COLOR.ThemePrimary} fontSize={layout.window.toNormH(26)}/>
          </div>
        </div>) : <div className="d-flex flex-column justify-content-center align-items-center" style={styles.emptyContainer}>
            <FontAwesomeIcon icon={faTimesCircle} style={styles.crossIcon}/>
            <p style={styles.emptyText}>No closure requests found</p>
          </div>}
      </div>
      <ClosureDetailsModal visible={modalUserData} ticketDetails={modalUserData} onClose={onCloseModal}/>
    </div>
  )
}
