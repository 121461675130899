import { useNavigate } from "react-router-dom";
import { theme } from "../../../constants/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCheckSquare, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { AccessRoles, APICallStatus } from "../../../constants";
import { fetchAuditorKycUserList } from "../../../redux/thunks/kyc.thunk";
import { useDispatch, useSelector } from "react-redux";
import layout from "../../../constants/layout";
import AssignAgents from "../AssignAgents";
import ClosureRequests from "../ClosureRequests";
import PreKycDashboard from "../PreKycDashboard";
import UpdateUserDocs from "../UpdateUserDocs";
import { Layout, Menu, Badge } from 'antd';
import { BulbOutlined, CloseSquareOutlined, DashboardOutlined, DesktopOutlined, LinkOutlined, PaperClipOutlined, SettingOutlined, UserSwitchOutlined } from '@ant-design/icons';
import ManageTransactions from "../ManageTransactions";
import AccountClosure from '../AccountClosure';
import ApprovedUsers from '../ApprovedUsers';
import { loggedInUserRoleSelector, loggedInUserSelector } from "../../../redux/selectors/app";
import { icon } from "@fortawesome/fontawesome-svg-core";
import PaymentLinkGenerator from "../PaymentLinkGenerator";

const { Header, Content, Sider, Footer } = Layout;

function AdminDashboard({

}) {
  const navigate = useNavigate();
  const userRole = useSelector(loggedInUserRoleSelector);
  const isCsaAgent = userRole === AccessRoles.CSA;
  const isBackOfficeAdmin = userRole === AccessRoles.BACKOFFICE_ADMIN;
  const [collapsed, setCollapsed] = useState(false);
  const [layoutMarginLeft, setLayoutMarginLeft] = useState(220);
  const [ipAddress, setIpAddress] = useState('');
  const [locationInfo, setLocationInfo] = useState(null);
  const [locationPermission, setLocationPermission] = useState('prompt');

  useEffect(() => {
    // Fetch IP address
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIpAddress(data.ip))
      .catch(error => console.error('Error fetching IP:', error));

    // Request location permission
    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' })
        .then(permissionStatus => {
          setLocationPermission(permissionStatus.state);
          if (permissionStatus.state === 'granted') {
            getLocation();
          } else if (permissionStatus.state === 'prompt') {
            getLocation();
          }
          permissionStatus.onchange = () => {
            setLocationPermission(permissionStatus.state);
          };
        });
    }
  }, []);

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        // Store coordinates for fallback display
        const coordinates = `${latitude.toFixed(6)}, ${longitude.toFixed(6)}`;
        // Use reverse geocoding to get location details
        fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=51f1d247e1b04f19bee15960b0582158`)
          .then(response => response.json())
          .then(data => {
            if (data.results && data.results[0]) {
              setLocationInfo(data.results[0].formatted);
            } else {
              // Use coordinates as fallback
              setLocationInfo(`Coordinates: ${coordinates}`);
            }
          })
          .catch(error => {
            console.error('Error fetching location info:', error);
            // Use coordinates as fallback
            setLocationInfo(`Coordinates: ${coordinates}`);
          });
      },
      error => {
        console.error('Error getting location:', error);
        setLocationPermission('denied');
      }
    );
  };

  const CardOptions = {
    ASSIGN_AGENT: 'ASSIGN_AGENT',
    ADD_AGENT_AUDTOR: 'ADD_AGENT_AUDITOR',
    CLOSURE_REQUESTS: 'CLOSURE_REQUESTS',
    PRE_KYC_DASHBOARD: 'PRE_KYC_DASHBOARD',
    UPDATE_USER_DOCS: 'UPDATE_USER_DOCS',
    MANAGE_TRANSACTIONS: 'MANAGE_TRANSACTIONS',
    ACCOUNT_CLOSURE: 'ACCOUNT_CLOSURE',
    APPROVED_USERS: 'APPROVED_USERS',
    PAYMENT_LINK: 'PAYMENT_LINK',
  };

  const cards = isCsaAgent ? [{
    key: CardOptions.PRE_KYC_DASHBOARD,
    value: 'Kyc Controller',
    disabled: true,
    icon: <BulbOutlined />
  }, {
    key: CardOptions.ASSIGN_AGENT,
    value: 'Assign Agent',
    disabled: true,
    icon: <DesktopOutlined />
  }, {
    key: CardOptions.ADD_AGENT_AUDTOR,
    value: 'New Agent/Auditor',
    disabled: true,
    icon: <UserSwitchOutlined />
  }, {
    key: CardOptions.UPDATE_USER_DOCS,
    value: 'Update User Docs',
    disabled: true,
    icon: <PaperClipOutlined />
  },{
    key: CardOptions.MANAGE_TRANSACTIONS,
    value: 'Manage',
    icon: <SettingOutlined/>
  }, {
    key: CardOptions.ACCOUNT_CLOSURE,
    value: 'Account Closure',
    disabled: true,
    icon: <CloseSquareOutlined />
  },{
    key: CardOptions.PAYMENT_LINK,
    value: 'Payment Link',
    icon: <LinkOutlined />
  }] : (isBackOfficeAdmin ? [{
    key: CardOptions.ACCOUNT_CLOSURE,
    value: 'Account Closure',
    icon: <CloseSquareOutlined />
  }] : [{
    key: CardOptions.PRE_KYC_DASHBOARD,
    value: 'Kyc Controller',
    icon: <BulbOutlined />
  },
  {
    key: CardOptions.ASSIGN_AGENT,
    value: 'Assign Agent',
    icon: <DesktopOutlined />
  }, {
    key: CardOptions.ADD_AGENT_AUDTOR,
    value: 'New Agent/Auditor',
    disabled: true,
    icon: <UserSwitchOutlined />
  },{
    key: CardOptions.UPDATE_USER_DOCS,
    value: 'Update User Docs',
    icon: <PaperClipOutlined />
  }, {
    key: CardOptions.MANAGE_TRANSACTIONS,
    value: 'Manage',
    icon: <SettingOutlined/>
  }, {
    key: CardOptions.ACCOUNT_CLOSURE,
    value: 'Account Closure',
    icon: <CloseSquareOutlined />
  },{
    key: CardOptions.PAYMENT_LINK,
    value: 'Payment Link',
    icon: <LinkOutlined />
  }]);

  const [selectedOption, setSelectedOption] = useState(isCsaAgent ? CardOptions.MANAGE_TRANSACTIONS : (isBackOfficeAdmin ? CardOptions.ACCOUNT_CLOSURE : CardOptions.PRE_KYC_DASHBOARD));

  const getDivision = () => {
    switch (selectedOption) {
      case CardOptions.ASSIGN_AGENT:
        return <AssignAgents/>;
      case CardOptions.CLOSURE_REQUESTS:
        return <ClosureRequests/>;
      case CardOptions.PRE_KYC_DASHBOARD:
        return <PreKycDashboard/>;
      case CardOptions.UPDATE_USER_DOCS:
        return <UpdateUserDocs/>;
      case CardOptions.MANAGE_TRANSACTIONS:
        return <ManageTransactions/>;
      case CardOptions.ACCOUNT_CLOSURE:
        return <AccountClosure/>;
      case CardOptions.PAYMENT_LINK:
        return <PaymentLinkGenerator/>;
      default:
        return null;
    }
  }
 
  return (
    <Layout style={{  }}>
      <Sider 
        collapsible collapsed={collapsed} onCollapse={(value) => {
          setCollapsed(value)
          if(value==true){
            setLayoutMarginLeft(80)
          }else{
            setLayoutMarginLeft(220)
          }
        }} 
        width={layoutMarginLeft}
        breakpoint='lg'
        theme="dark"
        style={{ 
          position: 'fixed',
          height: '100vh',
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 999,
          backgroundColor: '#000000',
          overflow: 'hidden'
        }}
      >
        <div className='logo'>
          <img 
            src="https://havemony.com/mony_logo.svg" 
            alt="Mony Logo" 
            style={{ 
              width: '80px',
              height: 'auto',
              margin: '16px auto',
              display: 'block'
            }} 
          />
        </div>
        <Menu 
          theme='dark' 
          mode='inline' 
          defaultSelectedKeys={[selectedOption]}
          selectedKeys={[selectedOption]}
          onSelect={({ key }) => setSelectedOption(key)}
          style={{
            backgroundColor: '#000000',
            borderRight: 0
          }}
        >
          {cards?.map(card => (
            <Menu.Item 
              key={card.key} 
              icon={card.icon}
              style={{
                backgroundColor: selectedOption === card.key ? '#613AE7' : 'transparent'
              }}
              disabled={card.disabled}
            >
              {card.value} {(card.key === CardOptions.ASSIGN_AGENT || card.key === CardOptions.PAYMENT_LINK) && <Badge count="NEW" style={{ backgroundColor: theme.COLOR.ThemePrimary, marginLeft: '8px' }} />}
            </Menu.Item>
          ))}
        </Menu>

        {/* Location Info Section */}
        {!collapsed && <div style={{
          position: 'absolute',
          bottom: '70px',
          left: 0,
          right: 0,
          padding: '16px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: '#fff',
          fontSize: '12px'
        }}>
          {locationPermission === 'prompt' && (
            <div style={{ marginBottom: '8px', color: '#ffd700' }}>
              Please allow location access
            </div>
          )}
          {locationPermission === 'denied' && (
            <div style={{ marginBottom: '8px', color: '#ff4d4f' }}>
              Location access denied
            </div>
          )}
          <div style={{ marginBottom: '4px' }}>
            IP: {ipAddress || 'Loading...'}
          </div>
          {locationInfo && (
            <div style={{ wordBreak: 'break-word' }}>
              Location: {locationInfo}
            </div>
          )}
        </div>}
      </Sider>
      <Layout style={{ marginLeft: layoutMarginLeft }}>
        {/* <Header style={{ background: '#fff', padding: 0 }} /> */}
        <Content>
          {getDivision()}
        </Content>
        <Footer style={{ position: 'fixed', bottom: 0, left: 0, right: 0, textAlign: 'center', padding: '20px', backgroundColor: '#f0f2f5' }}>
          Made with 💜 in Bangalore -  Copyright 2025, All Rights Reserved by Mony
        </Footer>
      </Layout>
    </Layout>
  );
}

export default AdminDashboard;
