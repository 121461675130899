import React, { useEffect, useState } from 'react'
import { styles } from './styles'
import { fetchAllAgents, validateBookingId, pickBooking, fetchKycUsersListByAgentUuid } from '../../../redux/thunks/kyc.thunk';
import { APICallStatus } from '../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faChevronCircleRight, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../../constants/theme';
import layout from '../../../constants/layout';
import { getFormattedDateTime } from '../../../service/codeUtils';
import AgentUserMapModal from '../../modals/AgentUserMapModal';
import Search from 'antd/es/input/Search';
import { Button, Card, Dropdown, Modal, Space, Typography, Table, Select } from 'antd';
import { showErrorToast, showInfoToast, showSuccessToast } from '../../../service/toast';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

export default function AssignAgents() {
  const [agents, setAgents] = useState([]);
  const [items, setItems] = useState([]);
  const [bookingIdData, setBookingIdData] = useState();
  const [searchLoading, setSearchLoading] = useState(false)
  const [agentDropDownDisabled, setAgentDropDownDisabled] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState();
  const [bookingId, setBookingId] = useState();
  const [kycList, setKycList] = useState([]);
  const [selectedAgentForList, setSelectedAgentForList] = useState(null);
  const [isLoadingKycList, setIsLoadingKycList] = useState(false);


  const onLoad = async () => {
    const response = await fetchAllAgents();
    console.log('sjjsjsjjs', response);
    if (response?.status === APICallStatus.SUCCESS) {
      setAgents(response?.agents);
      let items = []
      response?.agents?.map((agents, key) => {
        items.push(
          {
            label: agents?.name + " ("+ agents?.phoneNumber + ")",
            key: key,
            icon: <UserOutlined />,
            danger: false,
          }
        )
        if (key === response?.agents?.length - 1) {
          setItems(items)
        }
      })
    }
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async() => {
    setModalLoading(true)
    let res = await pickBooking(bookingId, selectedAgent?.uuid)
    console.log(res)
    if (res?.status === APICallStatus.SUCCESS) {
      let bookingIdToUpdate = bookingId
      setBookingIdData(null)
      setSearchLoading(false)
      setBookingId(null)
      setAgentDropDownDisabled(true)
      showSuccessToast("Booking Assigned.")
      setIsModalOpen(false);
      setSelectedAgent(null)
      setModalLoading(false)
      validateBooking(bookingIdToUpdate)
      return
    }else{
      showErrorToast(res?.message || "Error in assigning Booking Id")
      setModalLoading(false)
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const handleMenuClick = (e) => {
    console.log('click', e?.key);
    console.log(agents[e?.key])
    setSelectedAgent(agents[e?.key])
    showModal()
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };




  const validateBooking = async (bookingId) => {
    if (bookingId === '') {
      setAgentDropDownDisabled(true)
      return
    }
    setSearchLoading(true)
    setBookingId(bookingId)
    let res = await validateBookingId(bookingId)
    console.log(res)
    if (res?.status === APICallStatus.SUCCESS) {
      setBookingIdData(res?.usersList)
      setSearchLoading(false)
      setAgentDropDownDisabled(false)
      showInfoToast("Booking Id Validated. Please proceed with agent assignment.")
      return
    }
    showErrorToast(res?.message || "Invalid Booking Id")
    setSearchLoading(false)
    setAgentDropDownDisabled(true)
  }

  const handleAgentSelect = async (value) => {
    setSelectedAgentForList(value);
    setIsLoadingKycList(true);
    console.log('ncnncncn',value);
    try {
      const response = await fetchKycUsersListByAgentUuid(value);
      console.log(response);
      if (response?.status === APICallStatus.SUCCESS) {
        setKycList(response.usersList || []);
      } else {
        showErrorToast(response?.message || 'Failed to fetch KYC list');
      }
    } catch (error) {
      showErrorToast('Error fetching KYC list');
    } finally {
      setIsLoadingKycList(false);
    }
  };

  const columns = [
    {
      title: 'Booking Id',
      dataIndex: 'bookingId',
      key: 'bookingId',
    },
    {
      title: 'Name',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'userPhoneNumber',
      key: 'userPhoneNumber',
    },
    {
      title: 'Nationality',
      dataIndex: 'nationalityType',
      key: 'nationalityType',
    },
    {
      title: 'Preferred Date & Time',
      dataIndex: 'preferredDateTime',
      key: 'preferredDateTime',
      render: (text) => getFormattedDateTime(text)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Button color="purple" variant="filled">
      OPEN
    </Button>
    }
  ];

  return (
    <div className='d-flex flex-column' style={{ gap: '24px' }}>
      <div className='d-flex flex-row' style={{ ...styles.container, gap: '24px' }}>
        <Card title="Assign Agent to Booking" className='w-full'>
          <Search
            placeholder="Enter Booking Id"
            allowClear
            enterButton="Search"
            size="large"
            onSearch={(value) => validateBooking(value)}
            className='w-full'
            onClear={() => setBookingIdData(null)}
            loading={searchLoading}
          />
          <div style={{height:"20px"}}></div>
          <div className=''>
           Name : <Typography.Text type="success" style={{ fontWeight: "bolder" }}>{bookingIdData?.name ? bookingIdData?.name : "NA"}</Typography.Text>
          </div>
          <div>
          Assigned Agent : <Typography.Text type="success" style={{ fontWeight: "bolder" }}>{bookingIdData?.assignedAgent ? bookingIdData?.assignedAgent : "Not Yet Assigned"}</Typography.Text>
          </div>
        </Card>
        <Card title="Assign Agent to Booking" className='w-full'>
          <Dropdown menu={menuProps} disabled={agentDropDownDisabled}>
            <Button>
              <Space>
                Assign Agent
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Card>
      </div>

      <Card title="Agent KYC List" className='w-full'>
        <div style={{ marginBottom: 16 }}>
          <Select
            placeholder="Select an agent to view their KYC list"
            style={{ width: 300 }}
            onChange={handleAgentSelect}
            value={selectedAgentForList}
          >
            {agents.map((agent) => (
              <Select.Option key={agent.uuid} value={agent.uuid}>
                {agent.name} ({agent.phoneNumber})
              </Select.Option>
            ))}
          </Select>
        </div>

        <Table
          columns={columns}
          dataSource={kycList}
          loading={isLoadingKycList}
          rowKey="bookingId"
          pagination={{ pageSize: 10 }}
          scroll={{ y: 400 }}
          style={{ height: '500px' }}
        />
        <div style={{height:"60px"}}></div>
      </Card>

      <Modal title="Confirm Agent Assignment" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} loading={modalLoading}>
        <p>Are you sure you want to assign booking Id <strong>{bookingId}</strong> to <strong>{selectedAgent?.name}</strong> (<strong>{selectedAgent?.phoneNumber}</strong>) </p>
      </Modal>
    </div>
  )
}
